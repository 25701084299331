import { useState, useContext, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import './rapportChantierGeneral.scss'

const RapportChantierGeneral = ({ rapport, onBack, onUpdateRapport }) => {
  const { utilisateur } = useContext(AuthContext)
  const [openDialogCommentaire, setOpenDialogCommentaire] = useState(false)
  const [commentaire, setCommentaire] = useState({
    contenu: '',
    utilisateurId: utilisateur._id
  })
  const [securiteRespectee, setSecuriteRespectee] = useState(false)
  const [qualiteTravailRespectee, setQualiteTravailRespectee] = useState(false)

  // Update state rapport
  useEffect(() => {
    if (rapport && rapport.observationChantier) {
      setSecuriteRespectee(rapport.observationChantier.securiteRespectee)
      setQualiteTravailRespectee(rapport.observationChantier.qualiteTravailRespectee)
      if (rapport.observationChantier.commentaires && rapport.observationChantier.commentaires.length > 0) {
        setCommentaire(rapport.observationChantier.commentaires[0])
      }
    }
  }, [rapport])

  const handleOpenDialogCommentaire = () => {
    setOpenDialogCommentaire(true)
  }

  const handleCloseDialogCommentaire = () => {
    setOpenDialogCommentaire(false)
  }

  const handleAjouterCommentaire = () => {
    // Logic add a comment
    handleCloseDialogCommentaire()
  }

  const handleToggleSecurite = () => {
    setSecuriteRespectee(!securiteRespectee)
  }

  const handleToggleQualiteTravail = () => {
    setQualiteTravailRespectee(!qualiteTravailRespectee)
  }

  const handleValiderRapport = async () => {
    try {
      const updatedData = {
        observationChantier: {
          commentaires: rapport.observationChantier.commentaires, // assuming you have logic to update this
          securiteRespectee,
          qualiteTravailRespectee
        }
      }

      const response = await axios.put(`/rapportsvisite/observationchantier/${rapport._id}`, updatedData)
      if (response.data) {
        onUpdateRapport(response.data) // Mettre à jour le rapport dans le composant parent
        onBack() // Retourner à l'étape précédente
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du rapport:', error)
    }
  }

  return (
    <div className="rapport-chantier-general">
      <h1>Rapport Chantier General</h1>

      <div className="btn-ajout-comm-reu-rapport-chantier-general">
        <button onClick={handleOpenDialogCommentaire}>+</button>
        <label>Ajouter un Commentaire</label>
      </div>

      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={securiteRespectee} onChange={handleToggleSecurite} />}
          label="Sécurité Respectée"
        />
        <FormControlLabel
          control={<Checkbox checked={qualiteTravailRespectee} onChange={handleToggleQualiteTravail} />}
          label="Qualité du Travail Respectée"
        />
      </FormGroup>
      <button color="primary" onClick={handleValiderRapport}>
        Valider le Rapport
      </button>

      <Dialog open={openDialogCommentaire} onClose={handleCloseDialogCommentaire}>
        <DialogTitle>Ajouter un Commentaire</DialogTitle>
        <DialogContent>
          <TextField
            label="Commentaire"
            multiline
            rows={4}
            value={commentaire.contenu}
            onChange={(e) => setCommentaire({ ...commentaire, contenu: e.target.value })}
            fullWidth
          />
          <Button onClick={handleAjouterCommentaire}>Ajouter</Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RapportChantierGeneral
