import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import './btnChangeMyDay.scss'

const BtnChangeMyDay = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const rapport = location.state?.rapport
  const [autresTaches, setAutresTaches] = useState([])
  const [tachesDuRapport, setTachesDuRapport] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchRapportDetails = async () => {
      try {
        setLoading(true)
        const rapportResponse = await axios.get(`/rapports/rapports/${rapport._id}`)
        setTachesDuRapport(rapportResponse.data.tachesDay)
        const responseAutresTaches = await axios.get(`/affaires/taches/${rapport.affaireId}`)
        setAutresTaches(responseAutresTaches.data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchRapportDetails()
  }, [rapport])

  const handleTacheClick = (tacheDay) => {
    navigate('/changeMyTache', { state: { tacheDay, rapportId: rapport._id } })
  }

  const renderTachesDuRapport = () => {
    if (loading) return <p>Chargement...</p>
    if (error) return <p>Erreur: {error.message}</p>

    return tachesDuRapport.map((tacheDay, index) => (
      <div key={index} onClick={() => handleTacheClick(tacheDay)}>
        <h3>{tacheDay.tacheIdDay?.nomTache || 'Nom de tâche inconnu'}</h3>
        <ul>
          {tacheDay.assignesTacheDay.map((assigne, assigneIndex) => (
            <li key={assigneIndex}>{assigne.nom || 'Nom inconnu'}</li>
          ))}
        </ul>
      </div>
    ))
  }

  const renderAutresTaches = () => {
    return autresTaches.map((tache, index) => (
      <div key={index}>
        <h3>{tache.nomTache}</h3>
      </div>
    ))
  }

  return (
    <div className="btn-change-my-day">
      <button onClick={() => navigate(-1)}>Retour</button>
      <div className="titleBtnChangeMyDay">

      <h2>Modifier Ma Journée</h2>
      </div>
      <div className="listBtnChangeMyDay">
      {renderTachesDuRapport()}
      </div>
      <div className="list2BtnChangeMyDay">
      <h2>{"Autres Tâches de l'Affaire"}</h2>
      {renderAutresTaches()}
      </div>
    </div>
  )
}

export default BtnChangeMyDay
