import { useState } from 'react';
import axios from 'axios';
import { TextField } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fileToKey } from '../../../utils/storage';
import { useFirebase } from '../../../context/Firebase';
import "./envoieBesoinMateriel.scss";


const EnvoieBesoinMateriel = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tache, date , utilisateur } = location.state || {};
    const [besoin, setBesoin] = useState('');
    const [details, setDetails] = useState('');
    const [file, setFile] = useState(null);
    const { storage } = useFirebase();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const affaireData = (await axios.get(`/affaires/${tache.affaireId}`)).data;
        let imgURL = '';
        if (file) {
          imgURL = await fileToKey(file, storage, { path: 'seb' });
        }
  
        const messageData = {
          typeTache: 'messagerie',
          userId: utilisateur._id,
          nomTache: `Besoin en matériel sur: ${affaireData.nomAffaire}`,
          description: `L'équipe est sur la ou les tâches: ${tache.nomTache}\nBesoin: ${besoin}\nDétails: ${details}`,
          usersId: affaireData.usersAssignes,
          img: imgURL
        };
  
        await axios.post('/taches/createMessageRapport', messageData);
        console.log('Succès: Données envoyées');
        navigate('/');
      } catch (error) {
        console.error('Erreur lors de l\'envoi du message', error);
      }
    };
  
    return (
  <div className="lack-materiel">
  <div className="btn-retour-lack-materiel">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <button className="btn-lack-materiel">Retour</button>
        </Link>
        </div>
    <h2>Signaler un Besoin en Matériel</h2>
    <form onSubmit={handleSubmit}>
      <TextField
        label="Besoin ou Arret en Matériel"
        value={besoin}
        onChange={(e) => setBesoin(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Détails supplémentaires"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <div className="dropzone">
    <input
      id="file"
      name="img"
      type="file"
      onChange={(e) => {
        const file = e.target.files[0]; // Prend le premier fichier
        if (file) {
          // Créez un aperçu du fichier si nécessaire
          file.preview = URL.createObjectURL(file); // Crée un aperçu pour les images
          setFile(file); // Met à jour l'état du composant avec le fichier sélectionné
        }
      }}
    />
    {file && (
      file.type.includes('image') ?
      <img src={file.preview} alt="Aperçu" style={{ width: '100%', maxHeight: '200px' }} /> :
      <div className="preview">
        <InsertDriveFile sx={{ fontSize: 40 }} />
        <CheckCircleOutline sx={{ fontSize: 40 }} />
      </div>
    )}
  </div>
      <button type="submit" className="btn-lack-materiel">
        Envoyer
      </button>
    </form>
  
  </div>
    )
  }

export default EnvoieBesoinMateriel