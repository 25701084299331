import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box
} from '@mui/material'
import './reunionRapportVisite.scss'
import { AuthContext } from '../../../../context/AuthContext'

const ReunionRapportVisite = ({ affaire, rapport, onBack, onUpdateRapport }) => {
  const { utilisateur } = useContext(AuthContext)
  const [personnesPresentes, setPersonnesPresentes] = useState([])
  const [pointsAbordes, setPointsAbordes] = useState([])
  const [nouvellePersonne, setNouvellePersonne] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    role: ''
  })
  const [nouveauPoint, setNouveauPoint] = useState({
    intitule: '',
    descriptif: ''
  })
  const [openDialogPersonne, setOpenDialogPersonne] = useState(false)
  const [openDialogPoint, setOpenDialogPoint] = useState(false)
  const [openDialogCommentaire, setOpenDialogCommentaire] = useState(false)
  const [commentaire, setCommentaire] = useState({
    contenu: '',
    utilisateurId: utilisateur._id
  })
  const [pointSelectionne, setPointSelectionne] = useState(null)

  // Récupération des données du rapport
  useEffect(() => {
    try {
      if (rapport && rapport.compteRenduReunion) {
        setPersonnesPresentes(rapport.compteRenduReunion.presente || [])
        setPointsAbordes(rapport.compteRenduReunion.pointsAbordes || [])
      }
    } catch (error) {
      console.error('Error loading rapport data:', error)
    }
  }, [rapport])

  // Fonction pour ajouter une personne
  const handleAjouterPersonne = () => {
    setPersonnesPresentes([...personnesPresentes, nouvellePersonne])
    setNouvellePersonne({
      nom: '',
      prenom: '',
      email: '',
      telephone: '',
      role: ''
    })
  }

  // Fonction pour ajouter un point abordé
  const handleAjouterPoint = () => {
    setPointsAbordes([...pointsAbordes, { ...nouveauPoint, commentaires: [] }])
    setNouveauPoint({ intitule: '', descriptif: '' })
  }

  // Fonctions pour ouvrir et fermer les dialogues
  const handleOpenDialogPersonne = () => setOpenDialogPersonne(true)
  const handleCloseDialogPersonne = () => setOpenDialogPersonne(false)

  const handleOpenDialogPoint = () => setOpenDialogPoint(true)
  const handleCloseDialogPoint = () => setOpenDialogPoint(false)

  // Fonction pour mettre à jour le rapport
  const handleUpdateRapport = async () => {
    try {
      // Assuming you have the rapport ID available
      const rapportId = rapport._id

      // Prepare the data to be sent
      const updatedData = {
        compteRenduReunion: {
          presente: personnesPresentes,
          pointsAbordes
        }
      }

      console.log('updatedData', updatedData)
      const response = await axios.put(
        `/rapportsvisite/compterendureunion/${rapportId}`,
        updatedData
      )
      // Handle the response (you can also update the state here if needed)
      console.log('Rapport updated successfully:', response.data)
      if (response.data) {
        onUpdateRapport(response.data) // Mettre à jour le rapport dans le composant parent
        onBack() // Retourner à l'étape précédente
      }
    } catch (error) {
      // Handle errors
      console.error('Error updating the rapport:', error)
    }
  }
  // Fonction pour ajouter un commentaire à un point abordé

  const handleOpenDialogCommentaire = (point) => {
    setPointSelectionne(point)
    setOpenDialogCommentaire(true)
  }

  const handleCloseDialogCommentaire = () => {
    setOpenDialogCommentaire(false)
  }

  const handleAjouterCommentaire = () => {
    // Ajouter le commentaire au point sélectionné
    const pointMisAJour = {
      ...pointSelectionne,
      commentaires: [...pointSelectionne.commentaires, commentaire]
    }
    // Mettre à jour le point dans la liste des points abordés
    setPointsAbordes(
      pointsAbordes.map((p) => (p === pointSelectionne ? pointMisAJour : p))
    )
    handleCloseDialogCommentaire()
  }

  // Fonction pour afficher les personnes présentes
  const renderPersonnesPresentes = () => {
    return personnesPresentes.length > 0
      ? (
          personnesPresentes.map((personne, index) => (
        <div key={index} className="render-personne-reu-rapport-visit">
          <p>Nom: {personne.nom}</p>
          <p>Prénom: {personne.prenom}</p>
          <p>Email: {personne.email}</p>
          <p>Téléphone: {personne.telephone}</p>
          <p>Rôle: {personne.role}</p>
        </div>
          ))
        )
      : (
      <p>Aucune personne présente.</p>
        )
  }

  // Fonction pour afficher les points abordés

  const renderPointsAbordes = () => {
    return pointsAbordes.length > 0
      ? (
          pointsAbordes.map((point, index) => (
        <div key={index} className="render-point-abor-reu-rapport-visit">
          {' '}
          <p>Intitulé: {point.intitule}</p>
          <p>Descriptif: {point.descriptif}</p>
          <div className="btn-ajout-comm-reu-rapport-visit">
            <button onClick={() => handleOpenDialogCommentaire(point)}>
              +
            </button>
            <label>Ajouter un Commentaire</label>
          </div>
        </div>
          ))
        )
      : (
      <p>Aucun point abordé.</p>
        )
  }

  return (
    <div>
      <div className="contain-ReunionRapportVisite">
        <div className="btn-group-ReunionRapportVisite">
        <div className="btn-ajout-personne-reu-rapport-visit">
          <button onClick={handleOpenDialogPersonne}>+</button>
          <label>Ajouter une Personne</label>
        </div>
        <div className="btn-ajout-point-reu-rapport-visit">
          <button onClick={handleOpenDialogPoint}>+</button>
          <label>Ajouter un Point de Réunion</label>
        </div>
        </div>
        <div className="render-peronnes-ReunionRapportVisite">
          <h3>Personnes Présentes:</h3>
          <div>{renderPersonnesPresentes()}</div>
        </div>
        <div className="render-points-abor-ReunionRapportVisite">
          <h3>Points Abordés:</h3>
          <div>{renderPointsAbordes()}</div>
        </div>
        <button onClick={handleUpdateRapport}>Mettre à jour le Rapport</button>
      </div>

      {/* Formulaire pour ajouter une nouvelle personne */}
      <Dialog open={openDialogPersonne} onClose={handleCloseDialogPersonne}>
        <DialogTitle>Ajouter une Nouvelle Personne</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1 }, mb: 2 }}
          >
            <TextField
              label="Nom"
              value={nouvellePersonne.nom}
              onChange={(e) =>
                setNouvellePersonne({
                  ...nouvellePersonne,
                  nom: e.target.value
                })
              }
            />
            <TextField
              label="Prénom"
              value={nouvellePersonne.prenom}
              onChange={(e) =>
                setNouvellePersonne({
                  ...nouvellePersonne,
                  prenom: e.target.value
                })
              }
            />
            <TextField
              label="Email"
              value={nouvellePersonne.email}
              onChange={(e) =>
                setNouvellePersonne({
                  ...nouvellePersonne,
                  email: e.target.value
                })
              }
            />
            <TextField
              label="Téléphone"
              value={nouvellePersonne.telephone}
              onChange={(e) =>
                setNouvellePersonne({
                  ...nouvellePersonne,
                  telephone: e.target.value
                })
              }
            />
            <TextField
              label="Rôle"
              value={nouvellePersonne.role}
              onChange={(e) =>
                setNouvellePersonne({
                  ...nouvellePersonne,
                  role: e.target.value
                })
              }
            />
            <Button onClick={handleAjouterPersonne}>Ajouter</Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Formulaire pour ajouter un nouveau point abordé */}
      <Dialog open={openDialogPoint} onClose={handleCloseDialogPoint}>
        <DialogTitle>Ajouter un Nouveau Point de Réunion</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1 }, mb: 2 }}
          >
            <TextField
              label="Intitulé"
              value={nouveauPoint.intitule}
              onChange={(e) =>
                setNouveauPoint({ ...nouveauPoint, intitule: e.target.value })
              }
            />
            <TextField
              label="Descriptif"
              value={nouveauPoint.descriptif}
              onChange={(e) =>
                setNouveauPoint({ ...nouveauPoint, descriptif: e.target.value })
              }
            />
            <Button onClick={handleAjouterPoint}>Ajouter</Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Formulaire pour ajouter un commentaire à un point abordé */}
      <Dialog
        open={openDialogCommentaire}
        onClose={handleCloseDialogCommentaire}
      >
        <DialogTitle>Ajouter un Commentaire</DialogTitle>
        <DialogContent>
          <TextField
            label="Commentaire"
            multiline
            rows={4}
            value={commentaire.contenu}
            onChange={(e) =>
              setCommentaire({ ...commentaire, contenu: e.target.value })
            }
            fullWidth
          />
          <Button onClick={handleAjouterCommentaire}>Ajouter</Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ReunionRapportVisite
