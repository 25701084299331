import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import Button from '@mui/material/Button'
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu'
import './clockings.scss'
import CreateClocking from './components/createClocking'
import axios from 'axios'
import { format, formatDuration, intervalToDuration, isBefore } from 'date-fns'
import UpdateClocking from './components/updateClocking'

const Clockings = () => {
  const { utilisateur } = useContext(AuthContext)
  const [clockings, setClockings] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(`/utilisateurs/${utilisateur._id}/chronometres`)
      .then(res => setClockings(res.data.sort((a, b) => isBefore(new Date(a.debut), new Date(b.debut)) ? 1 : -1)))
  }, [])

  function timing (duree) {
    const duration = intervalToDuration({ start: 0, end: duree * 1000 })

    return formatDuration(duration, {
      format: ['hours', 'minutes'],
      zero: true,
      delimiter: 'h',
      locale: {
        formatDistance: (_token, count) => String(count).padStart(2, '0')
      }
    })
  }

  const handleFinishDay = (rapport) => {
    navigate('/questionreponseform', { state: { rapport } })
  }

  return (
    <div className='container'>
      <div className='header'>
        <HamburgerMenu />
        <CreateClocking callback={setClockings} />
      </div>
      <div className="content">
        <div className='title'>Pointages</div>
        <table>
          <thead>
            <tr>
              <th>Affaire</th>
              <th>Tache</th>
              <th>Date début</th>
              <th>Date fin</th>
              <th>Durée</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clockings.map(clocking => <tr key={clocking._id}>
              <td>{clocking.tacheId.affaireId?.nomAffaire}</td>
              <td>{clocking.tacheId.nomTache}</td>
              <td>{format(new Date(clocking.debut), 'dd/MM/yyyy HH:mm')}</td>
              <td>{clocking.fin ? format(new Date(clocking.fin), 'dd/MM/yyyy hh:mm') : ''}</td>
              <td>{clocking.duree ? timing(clocking.duree) : ''}</td>
              <td>{clocking.rapport && clocking.rapport.actif ? 'Journée en cours' : clocking.rapport ? 'Journée terminée' : clocking.fin ? 'Pointage effectué' : 'Pointage en cours'}</td>
              <td className="action">{clocking.rapport && clocking.rapport.actif
                ? <Button onClick={() => handleFinishDay(clocking.rapport)} color='primary' style={{ textTransform: 'none' }}>terminer</Button>
                : <UpdateClocking clocking={clocking} callback={setClockings} />}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Clockings
