import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import './checkAndConfirm.scss'

const CheckAndConfirm = ({ open, onClose, rapportId }) => {
  const [tachesDetails, setTachesDetails] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const rapportResponse = await axios.get(`/rapports/${rapportId}`)
        const tachesDay = rapportResponse.data.tachesDay || []

        const tachesInfos = await Promise.all(
          tachesDay.map(async (tacheDay) => {
            const tacheInfo = await axios.get(`/taches/${tacheDay.tacheIdDay}`)
            const utilisateursInfos = await Promise.all(
              tacheDay.assignesTacheDay.map((id) =>
                axios.get(`/utilisateurs/${id}`)
              )
            )

            return {
              tache: tacheInfo.data,
              utilisateurs: utilisateursInfos.map((resp) => resp.data)
            }
          })
        )

        setTachesDetails(tachesInfos)
      } catch (error) {
        console.error('Erreur lors de la récupération des détails', error)
      }
    }

    if (open) {
      fetchDetails()
    }
  }, [open, rapportId])

  const handleValidateTache = async () => {
    try {
      // Récupérer le rapport de fin de journée actuel
      const rapportResponse = await axios.get(`/rapports/${rapportId}`)
      const currentRapport = rapportResponse.data

      // modif etat de l'affaire si premier rapport et push dans le tableau
      // Récupérer l'affaire associée
      const affaireResponse = await axios.get(`/affaires/${currentRapport.affaireId}`)
      const currentAffaire = affaireResponse.data

      // Vérifier si c'est le premier rapport pour cette affaire
      if (currentAffaire.rapportsFinDeJournee.length === 0) {
        currentAffaire.statut = 'en cours'
        await axios.put(`/affaires/${currentAffaire._id}`, currentAffaire)
      }
      // Mise à jour de chaque tacheDay dans le rapport
      for (const tacheDay of currentRapport.tachesDay) {
        // Créer un objet chronomètre pour chaque tacheDay
        const chronometreData = {
          tacheId: tacheDay.tacheIdDay,
          debut: new Date(), // Supposons que le chronomètre commence maintenant
          fin: null, // fin est null pour l'instant
          duree: null, // duree est null pour l'instant
          utilisateurId: tacheDay.assignesTacheDay // Supposition d'un champ utilisateurId
        }

        // Envoyer l'objet chronomètre à l'API
        const chronoResponse = await axios.post(
          '/chronometres/',
          chronometreData
        )
        const chronoId = chronoResponse.data._id

        // Associer l'ID du chronomètre à la tacheDay correspondante dans le rapport
        tacheDay.chronometres.push(chronoId)

        // Mettre à jour la tâche avec le nouveau chronomètre
        await axios.put(`/taches/chronos/${tacheDay.tacheIdDay}`, {
          chronometreId: chronoId
        })
        // Mise à jour des utilisateurs assignés à chaque tâche
        for (const utilisateurId of tacheDay.assignesTacheDay) {
          await axios.put(`/utilisateurs/${utilisateurId}`, {
            tachesAssignees: [tacheDay.tacheIdDay],
            rapportsAssignees: [rapportId]
          })
        }
      }

      // Mise à jour du champ rapportsActifs dans la table dataday
      await axios.put(`/dataday/${currentRapport.dataDayId}`, {
        rapportsActifs: [currentRapport._id]
      })
      // Mettre à jour le rapport de fin de journée avec les nouveaux chronomètres
      // et actif a true
      // Nouvelle logique pour enregistrer l'heure d'arrivée pour chaque utilisateur
      const maintenant = new Date() // Heure actuelle
      const teamMemberHoursUpdates = currentRapport.tachesDay.flatMap(
        (tacheDay) =>
          tacheDay.assignesTacheDay.map((utilisateurId) => ({
            utilisateurId,
            arrivee: maintenant
          }))
      )
      await axios.put(`/rapports/${rapportId}`, {
        ...currentRapport,
        teamMemberHours: teamMemberHoursUpdates,
        actif: true
      })

      setSnackbarMessage('Enregistrement réussi')
      setOpenSnackbar(true)

      setTimeout(() => navigate('/'), 3000) // Redirige après 3 secondes
    } catch (error) {
      setSnackbarMessage('Erreur lors de la validation des tâches')
      setOpenSnackbar(true)
      console.error('Erreur lors de la validation des tâches', error)
    } finally {
      onClose()
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }
  console.log(tachesDetails)
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirmation des Tâches</DialogTitle>
        <DialogContent>
          {tachesDetails.map((tacheDetail, index) => (
            <div key={index}>
              <h3>{tacheDetail.tache.nomTache}</h3>
              {tacheDetail.utilisateurs.map((user) => (
                <div key={user._id}>{user.nom}</div>
              ))}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidateTache}>Valider</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  )
}

export default CheckAndConfirm
