import React from 'react'
import "./affaireResume.scss";
import useFetch from '../../Hooks/useFetch';
import { useParams, useNavigate } from 'react-router-dom'
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu';

const AffaireResume = () => {
    const { affaireId } = useParams()
    const navigate = useNavigate()

    const {data: affairedata,loading,error,reFetch,} = useFetch(`/affaires/tacheschantier/resume/${affaireId}`);
  
    const tachesChantier = affairedata && affairedata.taches ? affairedata.taches.filter(tache => tache.typeTache === "Chantier") : [];
    const tachesChantierTrie = React.useMemo(() => tachesChantier.sort((a, b) => new Date(a.dateDepartReelle) - new Date(b.dateDepartReelle)), [tachesChantier]);
    const tachesAPrendre = affairedata && affairedata.taches ? affairedata.taches.filter(tache => tache.typeTache === "A Prendre") : [];
  
    const chefAssignes = affairedata && affairedata.chefAssignes ? affairedata.chefAssignes.map(chef => `${chef.nom} ${chef.prenom}`).join(", ") : "Non spécifié";
    const ouvrierTerrainAssignes = affairedata && affairedata.ouvrierTerrainAssignes ? affairedata.ouvrierTerrainAssignes.map(ouvrier => `${ouvrier.nom} ${ouvrier.prenom}`).join(", ") : "Non spécifié";
    const conducAssignes = affairedata && affairedata.conducAssignes ? affairedata.conducAssignes.map(conduc => `${conduc.nom} ${conduc.prenom}`).join(", ") : "Non spécifié";
  
    // pour affichage date 
    const formaterDate = (dateString) => {
        if (!dateString) return ''; // Retourne une chaîne vide si dateString n'est pas défini ou est vide
      
        const mois = [
          "janvier", "février", "mars", "avril", "mai", "juin",
          "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];
      
        // Extraire les composantes de la date à partir de la chaîne
        const [year, month, day] = dateString.split('T')[0].split('-');
      
        // Convertir le mois en index (0-11) puis récupérer le nom du mois dans le tableau
        const moisNom = mois[parseInt(month, 10) - 1];
      
        return `${parseInt(day, 10)} ${moisNom} ${year}`;
      };

      const handleBackClick = () => {
        navigate('/affaires')
      }

  return (
    <div className="AffaireResume">
        <div className='top-affaireDocuments'>
            <HamburgerMenu />
            <button onClick={handleBackClick} className="btn-retour">Retour</button>
        </div>
    <div className="header-AffaireResume">
    <h3>Présentation Chantier:</h3>
    <p>Nom du chantier: {affairedata.nomAffaire}</p>
    <p>Client: {affairedata.client}</p>
    <p>
      Adresse:{" "}
      {`${affairedata.adresse}, ${affairedata.codePostal} ${affairedata.ville}`}
    </p>
    </div>
 {/*<div className="equipe-prevus-rapport-depart-AffaireResume">
    <h3>Équipe prévue:</h3>
    <p>Chef d'équipe: {chefAssignes}</p>
  <p>Équipe prévue: {ouvrierTerrainAssignes}</p>
  <p>Conducteur de travaux: {conducAssignes}</p>
  </div> */}
  <div className="contact-client-rapport-depart-AffaireResume">
    <h3>Contact Client sur place:</h3>
    <p>Contact sur place: {affairedata.contactSurPlace}</p>
    <p>Téléphone: {affairedata.telContactSurPlace}</p>
  </div>
  <div className="date-chantier-prevus-rapport-depart-AffaireResume">
    <h3>Date Chantier Prévus:</h3>
    <p>
      Date de début réelle: {formaterDate(affairedata.dateDebutReelle)}
    </p>
    <p>Date de fin réelle: {formaterDate(affairedata.dateFinReelle)}</p>
  </div>
  <div className="a-prendre-rapport-depart-AffaireResume">
  <h3>A Prendre pour le Chantier:</h3>
  {tachesAPrendre.length > 0 ? (
    tachesAPrendre.map((tache) => (
    <div key={tache._id} className="liste-tache-rapport-depart-AffaireResume">
     <p> - Nom: {tache.nomTache}</p>
     {tache.description && <p style={{ marginLeft: '20px' }}>Description: {tache.description}</p>}
    </div>
    ))
  ) : (
    <p>Vous n'avez rien à prendre.</p>
  )}
  </div>
  <div className="materiel-prevus-rapport-depart-AffaireResume">
    <h3>Tâches à réaliser:</h3>
    <br />
    {tachesChantierTrie.map((tache) => (
      <div key={tache._id} className="liste-tache-rapport-depart-AffaireResume">
        <p className="title-liste-tache-rapport-depart-AffaireResume"> - {tache.nomTache}</p>
        <div className="detail-ta-ra-de-AffaireResume">
          <p>Durée Prévus: {tache.duration} Jour(s)</p>
          <p>Date de début: {formaterDate(tache.dateDepartReelle)}</p>
          <p>Date de fin: {formaterDate(tache.dateEcheanceReelle)}</p>
          {tache.materielAPrendre && tache.materielAPrendre.length > 0 && (
            <div className="a-prendre-pour-taches-rapport-depart-AffaireResume">
              <p>Matériel à prendre pour cette tache:</p>
              <ul>
                {tache.materielAPrendre.map((materiel) => (
                  <li key={materiel._id} style={{ marginLeft: '20px' }}>
                    {`- ${materiel.nom} (Quantité: ${materiel.quantite})`}
                  </li>
                ))}
              </ul>
            </div>
            )}
        </div>
     </div>
    ))}
 </div>
 </div>
  )
}

export default AffaireResume