import { useContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from './Pages/Login/Login'
import Reset from './Pages/Reset/Reset'
import Forgot from './Pages/Forgot/Forgot'
import Home from './Pages/Home/Home'
import Affaire from './Pages/Affaire/Affaire'
import SingleAffaire from './Pages/SingleAffaire/SingleAffaire'
import BlocAffaire from './Pages/BlocAffaire/BlocAffaire'
import { AuthContext } from './context/AuthContext'
import DefineMyTeam from './Components/RapportDay/StartDay/DefineMyTeam/DefineMyTeam'
import DefineMyJob from './Components/RapportDay/StartDay//DefineMyJob/DefineMyJob'
import DefineMyTache from './Components/RapportDay/StartDay/DefineMyTache/DefineMyTache'
import AssignMyTache from './Components/RapportDay/StartDay/AssignMyTache/AssignMyTache'
import CheckAndConfirm from './Components/RapportDay/StartDay/CheckAndConfirm/CheckAndConfirm'
import QuestionReponseForm from './Components/RapportDay/EndMyDay/QuestionReponseForm/QuestionReponseForm'
import BtnChangeMyDay from './Components/RapportDay/ChangeMyDay/BtnChangeMyDay/BtnChangeMyDay'
import ChangeMyTache from './Components/RapportDay/ChangeMyDay/ChangeMyTache/ChangeMyTache'
import ChooseTheChange from './Components/RapportDay/ChangeMyDay/ChooseTheChange/ChooseTheChange'
import ChangeMyTacheSelect from './Components/RapportDay/ChangeMyDay/ChangeMyTacheSelect/ChangeMyTacheSelect'
import ChangeMyTeam from './Components/RapportDay/ChangeMyDay/ChangeMyTeam/ChangeMyTeam'
import ConfirmAndStartChrono from './Components/RapportDay/ChangeMyDay/ConfirmAndStartChrono/ConfirmAndStartChrono'
import ProgressionTachesDay from './Components/RapportDay/EndMyDay/progressionTachesDay/ProgressionTachesDay'
import Documents from './Pages/Documents/Documents'
import Calendrier from './Pages/calendrier/Calendrier'
import Workflow from './Components/homeConduc/btnNewAffaireTs/Workflow/Workflow'
import CreationRapportDeVisite from './Components/homeConduc/btnNewRapportConduc/CreationRapportDeVisite/CreationRapportDeVisite'
import AjoutBl from './Components/RapportDay/AjoutBl/AjoutBl'
import LackMateriel from './Components/RapportDay/LackMateriel/LackMateriel'
import Clockings from './Pages/clockings/clockings'
import CalendrierAssignation from './Pages/calendrierAssignation/CalendrierAssignation'
import TacheId from './Pages/tacheId/TacheId'
import Messagerie from './Pages/messagerie/Messagerie'
import EnvoieBesoinMateriel from './Components/rappordayNew/envoieBesoinMateriel/EnvoieBesoinMateriel'
import HomeNew from './Pages/HomeNew/HomeNew'
import MessagerieAffaire from './Pages/messagerieAffaire/MessagerieAffaire'
import AffaireResume from './Pages/AffaireResume/AffaireResume'

function App() {
  const ProtectedRoute = ({ children }) => {
    const { utilisateur } = useContext(AuthContext)

    if (!utilisateur) {
      return <Navigate to="/login" />
    }

    return children
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="reset" element={<Reset />} />
          <Route
            index
            element={
              <ProtectedRoute>
                <HomeNew />
              </ProtectedRoute>
            }
          />
          <Route path="/clockings" element={<ProtectedRoute><Clockings /></ProtectedRoute>} />
          <Route path="/messagerie" element={<ProtectedRoute><Messagerie /></ProtectedRoute>} />
          <Route path="/messagerie/affaire/:id" element={<ProtectedRoute><MessagerieAffaire /></ProtectedRoute>} />
          <Route path="/definemyteam" element={<ProtectedRoute><DefineMyTeam /></ProtectedRoute>} />
          <Route path="/definemyjob" element={<ProtectedRoute><DefineMyJob /></ProtectedRoute>} />
          <Route path="/definemytache" element={<ProtectedRoute><DefineMyTache /></ProtectedRoute>} />
          <Route path="/assignmytache" element={<ProtectedRoute><AssignMyTache /></ProtectedRoute>} />
          <Route path="/checkandconfirm" element={<ProtectedRoute><CheckAndConfirm /></ProtectedRoute>} />
          <Route path="/questionreponseform" element={<ProtectedRoute><QuestionReponseForm /></ProtectedRoute>} />
          <Route path="/choosethechange" element={<ProtectedRoute><ChooseTheChange /></ProtectedRoute>} />
          <Route path="/changemytacheselect" element={<ProtectedRoute><ChangeMyTacheSelect /></ProtectedRoute>} />
          <Route path="/changemyteam" element={<ProtectedRoute><ChangeMyTeam /></ProtectedRoute>} />
          <Route path="/changemyday" element={<ProtectedRoute><BtnChangeMyDay /></ProtectedRoute>} />
          <Route path="/changemytache" element={<ProtectedRoute><ChangeMyTache /></ProtectedRoute>} />
          <Route path="/confirmandstartchrono" element={<ProtectedRoute><ConfirmAndStartChrono /></ProtectedRoute>} />
          <Route path="/progressiontachesday" element={<ProtectedRoute><ProgressionTachesDay /></ProtectedRoute>} />
          <Route path="/ajoutbl" element={<ProtectedRoute><AjoutBl /></ProtectedRoute>} />
          <Route path="/lackmateriel" element={<ProtectedRoute><LackMateriel /></ProtectedRoute>} />
          <Route path="/envoiebesoin" element={<ProtectedRoute><EnvoieBesoinMateriel /></ProtectedRoute>} />
          <Route path="/newaffairets" element={<ProtectedRoute><Workflow /></ProtectedRoute>} />
          <Route path="/newrapportconduc" element={<ProtectedRoute><CreationRapportDeVisite /></ProtectedRoute>} />
          <Route path="/affaires" element={<ProtectedRoute><Affaire /></ProtectedRoute>} />
          <Route path="/affaires/:id" element={<ProtectedRoute><SingleAffaire /></ProtectedRoute>} />
          <Route path="/resum/:affaireId" element={<ProtectedRoute><AffaireResume /></ProtectedRoute>} />
          <Route path="/calendrier" element={<ProtectedRoute><Calendrier /></ProtectedRoute>} />
          <Route path="/calendrierassignation" element={<ProtectedRoute><CalendrierAssignation /></ProtectedRoute>} />
          <Route path="/documents/:affaireId" element={<ProtectedRoute><Documents /></ProtectedRoute>} />
          <Route path="/tache/:tacheId" element={<ProtectedRoute><TacheId /></ProtectedRoute>} />
          <Route path="/affaires/:id/blocAffaires/remaining/:type" element={<ProtectedRoute><BlocAffaire /></ProtectedRoute>} />
          <Route path="/affaires/:id/blocAffaires/realize/:type" element={<ProtectedRoute><BlocAffaire /></ProtectedRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
