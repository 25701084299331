import { useState, useEffect, useContext } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { AuthContext } from '../../context/AuthContext'
import useFetch from '../../Hooks/useFetch'
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu'
import './calendrier.scss'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ItemClick from '../../Components/itemClick/ItemClick'

const Calendrier = () => {
  const { utilisateur } = useContext(AuthContext);
  const { data: tachesAffaires, loading } = useFetch(`/affaires/tacheschantier/${utilisateur._id}`);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [markedDates, setMarkedDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [affairesFiltrees, setAffairesFiltrees] = useState([]); // Nouvel état pour stocker les affaires filtrées

  useEffect(() => {
    if (loading || !tachesAffaires) return;

    const statutsAutorises = ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'];
    const affairesFiltreesTemp = tachesAffaires.filter(affaire => statutsAutorises.includes(affaire.statut));
    
    let allMarkedDates = [];
    affairesFiltreesTemp.forEach(affaire => {
      affaire.tachesChantier.forEach(tache => {
        const workDays = calculateWorkDays(tache.dateDepart, tache.dateEcheance);
        allMarkedDates.push(...workDays);
      });
    });
    setMarkedDates(allMarkedDates.map(date => date.toISOString().split('T')[0]));
    setAffairesFiltrees(affairesFiltreesTemp); // Mise à jour de l'état avec les affaires filtrées
  }, [tachesAffaires, loading]);

  // Définir handleClose
  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  // Définir handleOpen pour ouvrir le dialogue avec l'ID sélectionné
  const handleOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  const calculateWorkDays = (start, end) => {
    let startDate = new Date(start);
    let endDate = new Date(end);
    let workDays = [];
    while (startDate <= endDate) {
      if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
        // Utiliser la date locale sans conversion en UTC
        workDays.push(new Date(startDate));
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    return workDays;
  };
    const renderTasksForSelectedDate = () => {
      const isWeekend = selectedDate.getDay() === 0 || selectedDate.getDay() === 6;
    
      if (loading || !tachesAffaires) {
        return isWeekend ? <div>Aucune tâche n'est prévue pour les weekends.</div> : <div>Chargement...</div>;
      }
    
      const formatDate = selectedDate.toLocaleDateString('fr-FR', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
      });
    
      const selectedDateString = selectedDate.toISOString().split('T')[0];
      let showAPrendre = false;
    
      let tasksForDate = [];
      affairesFiltrees.forEach(affaire => {
        const tachesChantier = affaire.tachesChantier.filter(tache => {
          const startDateString = new Date(tache.dateDepart).toISOString().split('T')[0];
          const endDateString = new Date(tache.dateEcheance).toISOString().split('T')[0];
          const withinRange = selectedDateString >= startDateString && selectedDateString <= endDateString;
    
          if (withinRange) {
            showAPrendre = true;
          }
          return withinRange;
        });
    
        tasksForDate = [...tasksForDate, ...tachesChantier.map(tache => ({
          ...tache,
          nomAffaire: affaire.nomAffaire
        }))];
      });
    
      // Filtrer les tâches "à prendre" pour inclure uniquement celles appartenant aux affaires filtrées
      const tachesAPrendreToShow = tasksForDate.length > 0
        ? affairesFiltrees.flatMap(affaire => affaire.tachesAPrendre)
        : [];
    
      return (
        <div>
          <div>
            <h3 className='date-du-jour-render-tache'>{formatDate}</h3>
            {tasksForDate.map(tache => (
              <div key={tache._id} className='render-tache-solo'>
                <h4 className='title-tache-solo'>{tache.nomTache}</h4>
                <p>Affaire: {tache.nomAffaire}</p>
                <p>Date de début: {new Date(tache.dateDepart).toLocaleDateString('fr-FR')}</p>
                <p>Date de fin: {new Date(tache.dateEcheance).toLocaleDateString('fr-FR')}</p>
              </div>
            ))}
            {showAPrendre && <h4>A Prendre :</h4>}
            {tachesAPrendreToShow.map(tache => (
              <p className='item-click-calendrier' onClick={() => handleOpen(tache._id)} key={tache._id}>{tache.nomTache}</p>
            ))}
          </div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title">Détails de la tâche</DialogTitle>
            <DialogContent>
              {selectedId && <ItemClick idItem={selectedId} />}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    };

    const tileClassName = ({ date, view }) => (
      view === 'month' && markedDates.includes(date.toISOString().split('T')[0]) ? 'marked-date' : null
    );

  return (
    <div className='calendrier'>
      <HamburgerMenu />
      <div className="calendrier-calendrier">
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          locale="fr-FR"
          tileClassName={tileClassName}
        />
        <div className='render-tache-calendrier'>
          {renderTasksForSelectedDate()}
        </div>
      </div>
    </div>
  );
};

export default Calendrier;