import { useLocation, useNavigate } from 'react-router-dom'
import './changeMyTacheSelect.scss'
import axios from 'axios'
import { useEffect, useState } from 'react'
import ConfirmStopTache from '../ConfirmStopTache/ConfirmStopTache'

const ChangeMyTacheSelect = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const rapport = location.state?.rapport
  const [autresTaches, setAutresTaches] = useState([])
  const [tachesDuRapport, setTachesDuRapport] = useState([])
  // const [loading, setLoading] = useState(true)
  // const [error, setError] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedTache, setSelectedTache] = useState(null)
  const rapportId = rapport._id

  // fetch et trie les taches du rapport par rapport au tache de laffaire disponible soit elle sont deja dans
  // le rapport soit elle sont disponible on prend aussi le "fin" des chronometre pour savoir si il est en cour
  const fetchRapportDetails = async () => {
    try {
      // setLoading(true)
      const rapportResponse = await axios.get(
        `/rapports/rapports/${rapport._id}`
      )
      const tachesDuRapportData = rapportResponse.data.tachesDay

      const responseAutresTaches = await axios.get(
        `/affaires/taches/${rapport.affaireId}`
      )
      const tachesChantier = responseAutresTaches.data.filter(
        (tache) => tache.typeTache === 'Chantier'
      )

      // Extraction des IDs des tâches en cours (sans fin dans le chronomètre)
      const idsTachesEnCours = tachesDuRapportData
        .filter((taches) => taches.chronometres.some((chrono) => !chrono.fin))
        .map((tacheRapport) => tacheRapport.tacheIdDay?._id)

      // Séparation des tâches en cours et autres tâches
      const tachesEnCours = tachesChantier.filter((tache) =>
        idsTachesEnCours.includes(tache._id)
      )
      const autresTaches = tachesChantier.filter(
        (tache) => !idsTachesEnCours.includes(tache._id)
      )

      setTachesDuRapport(tachesEnCours)
      setAutresTaches(autresTaches)
    } catch (err) {
      console.error('Erreur lors du fetch des détails du rapport:', err)
      // setError(err)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    fetchRapportDetails() // Appel initial pour charger les données
  }, [rapport])

  const handleOpenDialog = (tache) => {
    setSelectedTache(tache)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedTache(null)
  }

  const handleTacheClick = (tache) => {
    navigate('/assignmytache', {
      state: {
        tacheId: tache._id,
        rapportId: rapport._id,
        originRoute: '/changemytacheselect'
      }
    })
  }

  const handleConfirmStop = async (rapportId, tacheId) => {
    try {
      const rapportResponse = await axios.get(
        `/rapports/rapports/${rapportId}`
      )
      const tacheDuRapport = rapportResponse.data.tachesDay.find(
        (tache) => tache.tacheIdDay?._id === tacheId
      )

      if (tacheDuRapport) {
        const chronoSansFin = tacheDuRapport.chronometres.find(
          (chrono) => !chrono.fin
        )

        if (chronoSansFin) {
          const fin = new Date()
          const debut = new Date(chronoSansFin.debut)
          const duree = (fin - debut) / 1000

          await axios.put(`/chronometres/${chronoSansFin._id}`, {
            fin: fin.toISOString(),
            duree
          })
          console.log('Chronomètre arrêté avec succès.')
        } else {
          console.log('Aucun chronomètre en cours pour cette tâche.')
        }
      } else {
        console.log('Tâche non trouvée dans le rapport.')
      }
    } catch (error) {
      console.error("Erreur lors de l'arrêt du chronomètre:", error)
    } finally {
      await fetchRapportDetails() // Recharger les données après l'arrêt du chronomètre
      handleCloseDialog() // Fermer la boîte de dialogue
    }
  }

  console.log('1', tachesDuRapport)
  console.log('2', autresTaches)
  return (
    <div className="ChangeMyTacheSelect">
      <button
        className="btnretour"
        onClick={() =>
          navigate('/choosethechange', { state: { rapport } })
        }
      >
        Retour
      </button>
      <div className="titleChangeMyTacheSelect">
        <h1>Modifier ma tache</h1>
      </div>
      <div className="containerChangeMyTacheSelect">
        <span>Tache en cour:</span>
        {tachesDuRapport.map((tache, index) => (
          <div
            className="desChangeMyTacheSelect"
            key={index}
            onClick={() => handleOpenDialog(tache)}
          >
            <h3>{tache.nomTache || 'Nom de tâche inconnu'}</h3>
          </div>
        ))}
        <div className="list2ChangeMyTacheSelect">
          <span> tache disponibles:</span>
          {autresTaches.map((tache, index) => (
            <div
              className="listChangeMyTacheSelect"
              key={index}
              onClick={() => handleTacheClick(tache)}
            >
              <h3>{tache.nomTache}</h3>
            </div>
          ))}
        </div>
      </div>
      <ConfirmStopTache
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => handleConfirmStop(rapportId, selectedTache?._id)}
        tache={selectedTache}
      />
    </div>
  )
}

export default ChangeMyTacheSelect
