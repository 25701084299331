import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './heuresTachePut.scss';

const HeuresTachePut = ({ rapports, tache, date, utilisateur }) => {
  const [heureModifiee, setHeureModifiee] = useState("");
  const [isEditing, setIsEditing] = useState(false);
console.log("tache dans heures tache put", tache);

  useEffect(() => {
    console.log("Affectations dans HeuresTachePut:", tache.affectations);
    const dateRecherche = new Date(date).toDateString();

    const affectation = tache.affectations.find(a => new Date(a.date).toDateString() === dateRecherche);
  
    if (affectation) {
      const roleUtilisateur = [...affectation.chefs, ...affectation.ouvriers].find(role => role.utilisateur._id === utilisateur._id);
  
      if (roleUtilisateur && roleUtilisateur.heures) {
        const heures = Math.floor(roleUtilisateur.heures);
        const minutes = Math.round((roleUtilisateur.heures % 1) * 60);
        setHeureModifiee(`${heures.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
      } else {
        console.log('No roleUtilisateur or hours are zero.');
      }
    } else {
      console.log('No matching affectation found for date:', dateRecherche);
    }
  }, [tache, date, utilisateur]);

  const handleTimeChange = e => {
    setHeureModifiee(e.target.value);
    setIsEditing(true);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    const [hours, minutes] = heureModifiee.split(':').map(Number);
    const dureeEnSecondes = (hours * 3600) + (minutes * 60);
    const dateUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

    const tacheUpdateData = {
      dureeEnSecondes,
      teamMembers: rapports.teamMembers,
      date: dateUTC
    };

    try {
      const response = await axios.put(`/taches/update/affectationsPhone/${tache._id}`, tacheUpdateData);
      console.log('Tâche mise à jour:', response.data);
      setIsEditing(false); // Désactiver l'édition après la sauvegarde
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche:', error);
    }
  };

  return (
    <div className='HeuresTachePut'>
      <div className="title-HeuresTachePut">
      <h3>Heures pour le jour {date.toLocaleDateString('fr-FR')}:</h3>
      </div>
      <div className="contain-HeuresTachePut">
      {isEditing ? (
        <>
          <input
            type="time"
            value={heureModifiee}
            onChange={handleTimeChange}
          />
          <button onClick={handleSave}>Valider</button>
        </>
      ) : (
        <>
          {heureModifiee && `${Math.floor(heureModifiee.split(":")[0])}h ${heureModifiee.split(":")[1]}m`}
          <button onClick={toggleEdit}>Modifier</button>
        </>
      )}
      </div>
    </div>
  );
};

export default HeuresTachePut;