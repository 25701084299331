import React from 'react';
import useFetch from '../../Hooks/useFetch';
import './itemClick.scss'; 

const ItemClick = ({ idItem }) => {
    const { data: dataItem, loading, error } = useFetch(`/taches/${idItem}`);

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>Erreur lors du chargement des données</p>;

    const getFileType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
        if (extension === 'pdf') return 'pdf';
        if (['xls', 'xlsx'].includes(extension)) return 'excel';
        return 'unknown';
    };

    const renderFile = (url) => {
        const fileType = getFileType(url);

        switch (fileType) {
            case 'image':
                return <img src={url} alt={dataItem.nomTache} style={{ maxWidth: '100%' }} />;
            case 'pdf':
                // Pour les appareils mobiles, privilégiez l'ouverture dans un nouvel onglet
                return <a href={url} target="_blank" rel="noopener noreferrer">Voir le fichier PDF</a>;
            case 'excel':
                return <a href={url} download>Voir le fichier Excel</a>;
            default:
                return <a href={url} download>Voir le fichier</a>;
        }
    };

    return (
        <div className='ItemClick'>
            <h3>Détails de la tâche</h3>
            <h4>Nom :</h4>
            <p>{dataItem.nomTache}</p>
            <h4>Description :</h4>
            <p>{dataItem.description}</p>
            {dataItem.img && (
                <div>
                    <h4>Document :</h4>
                    {renderFile(dataItem.img)}
                </div>
            )}
        </div>
    );
};

export default ItemClick;