import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AuthContextProvider } from './context/AuthContext'
import { FirebaseProvider } from './context/Firebase'
import axios from 'axios'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import fr from 'date-fns/locale/fr'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <FirebaseProvider>
      <AuthContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <App />
        </LocalizationProvider>
      </AuthContextProvider>
    </FirebaseProvider>
  </React.StrictMode>
)
