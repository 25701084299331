// ConfirmAndStartChrono.js
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'

const ConfirmAndStartChrono = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { tache, selectedMembers, rapportId } = location.state

  const handleStartChrono = async () => {
    try {
      // Créer un chronomètre pour la tâche sélectionnée
      const chronometreData = {
        tacheId: tache._id,
        debut: new Date(),
        fin: null,
        duree: null,
        utilisateurId: selectedMembers.map(member => member._id)
      }
      const chronoResponse = await axios.post('/chronometres/', chronometreData)
      const chronoId = chronoResponse.data._id

      // Récupérer le rapport de fin de journée actuel
      const rapportResponse = await axios.get(`/rapports/${rapportId}`)
      const currentRapport = rapportResponse.data

      // Trouver et mettre à jour la tâche dans le rapport avec le nouveau chronomètre
      const tacheIndex = currentRapport.tachesDay.findIndex(t => t.tacheIdDay === tache._id)
      if (tacheIndex !== -1) {
        currentRapport.tachesDay[tacheIndex].chronometres.push(chronoId)
      }

      // Mettre à jour le rapport
      await axios.put(`/rapports/${rapportId}`, currentRapport)

      navigate('/changemytacheselect', { state: { rapport: currentRapport } })
    } catch (error) {
      console.error('Erreur lors de la création du chronomètre', error)
    }
  }

  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Confirmer et démarrer le chronomètre</DialogTitle>
      <DialogContent>
        <h3>Tâche: {tache.nomTache}</h3>
        <div>Utilisateurs assignés:</div>
        {selectedMembers.map(member => (
          <div key={member._id}>{member.nom}</div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleStartChrono}>modifier la tache</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmAndStartChrono
