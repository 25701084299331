import React, { useState } from 'react';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TextField } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import './heuresTachePost.scss';


const HeuresTachePost = ({ tache, date, utilisateur,onClose }) => {
    const [confirmation, setConfirmation] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [dureeHeures, setDureeHeures] = useState(0);
    const [dureeMinutes, setDureeMinutes] = useState(0);

    const toLocalISOString = (date) => {
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - (offset * 60 * 1000));
        return localDate.toISOString().split('T')[0];
    };
    
    useEffect(() => {
      // Convertit la date d'entrée et la date d'affectation au format YYYY-MM-DD
      const dateStr = toLocalISOString(new Date(date));

      const affectationPourDate = tache.affectations.find(affectation => {
          // Convertit chaque date d'affectation au même format pour la comparaison
          const affectationDateStr = new Date(affectation.date).toISOString().split('T')[0];
          return affectationDateStr === dateStr;
      });

        if (affectationPourDate) {
            // Initialisez teamMembers avec les objets des ouvriers et ajoutez l'utilisateur (chef) à la fin
            const ouvriers = affectationPourDate.ouvriers.map(ouvrier => ({
                id: ouvrier.utilisateur._id,
                nom: ouvrier.utilisateur.nom,
                prenom: ouvrier.utilisateur.prenom
            }));
    
            const equipe = [...ouvriers, { id: utilisateur._id, nom: utilisateur.nom, prenom: utilisateur.prenom }];
            setTeamMembers(equipe);
        }
        console.log('Équipe pour cette tâche:', teamMembers , 'Date:', dateStr)
    }, [tache, date, utilisateur]);


const handleConfirmAffectations = async () => {
    const dateUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    const dureeEnSecondes = dureeHeures * 3600 + dureeMinutes * 60;
    try {
        console.log('Durée en secondes:', dureeEnSecondes)
        // Préparer les données pour le chronomètre
        const chronometreData = {
            tacheId: tache._id,
            duree: dureeEnSecondes,
            affaireId: tache.affaireId,
            date: dateUTC,
            utilisateurId: teamMembers.map(membre => membre.id)
        };

        // Envoyer la requête pour créer le chronomètre
        const chronometreResponse = await axios.post('/chronometres/phone', chronometreData);
        const chronometreId = chronometreResponse.data._id;
        console.log('Chronomètre créé:', chronometreResponse.data);

            // Préparer les données pour le rapport
            const rapportData = {
                chefAssignes: utilisateur._id,
                affaireId: tache.affaireId,
                date: dateUTC,
                tachesDay: [{
                    tacheIdDay: tache._id,
                    assignesTacheDay: teamMembers.map(membre => membre.id),
                    chronometres: [chronometreId],
                }],
                teamMembers: teamMembers.map(membre => membre.id),
                actif: true,
            };

             // Envoyer la requête pour créer le rapport
                // Envoyer la requête pour créer le rapport
                console.log('Données du rapport à créer:', rapportData);
                const rapportResponse = await axios.post('/rapports', rapportData);
                console.log('Rapport créé:', rapportResponse.data);

                // Mettre à jour la tâche avec l'ID du chronomètre et les heures
                const tacheUpdateData = {
                    chronometreId: chronometreId,
                    dureeEnSecondes: dureeEnSecondes,
                    teamMembers:  teamMembers.map(membre => membre.id),
                    date: dateUTC
                };
        

                // Mettre à jour la tâche avec l'ID du chronomètre et les heures
                console.log('Données de mise à jour de la tâche:', tacheUpdateData);
                const tacheResponse = await axios.put(`/taches/update/affectationsPhone/${tache._id}`, tacheUpdateData);
                console.log('Tâche mise à jour:', tacheResponse.data);

                onClose();
                    } catch (error) {
                        console.error('Erreur lors de la création du rapport ou du chronomètre:', error);
                    }
                };

            const handleTimeChange = (e) => {
                const [hours, minutes] = e.target.value.split(':');
                setDureeHeures(Number(hours));
                setDureeMinutes(Number(minutes));
            };

    // Crée une nouvelle date sans le temps pour aujourd'hui
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Fait la même chose pour la date passée au composant
    const taskDate = new Date(date);
    taskDate.setHours(0, 0, 0, 0);

    // Compare les dates
    const isFutureDate = taskDate > today;

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='HeuresTachePost'>
                {confirmation ? (
                    <div>
                        <input
                            type="time"
                            value={`${dureeHeures.toString().padStart(2, '0')}:${dureeMinutes.toString().padStart(2, '0')}`}
                            onChange={handleTimeChange}
                            step="300" // Incrémente/décrémente par tranches de 5 minutes
                        />
                    <button onClick={handleConfirmAffectations}>Soumettre les heures</button>
                </div>
                ) : (
                    <div className='list-HeuresTachePost'>
                        <p>Personnes assignées à cette tâche pour {date.toLocaleDateString('fr-FR')} :</p>
                        <ul>
                            {teamMembers.map((membre, index) => (
                                <li key={index}>{membre.nom} {membre.prenom}</li>
                            ))}
                        </ul>
                <button onClick={() => setConfirmation(true)} disabled={isFutureDate}>
                    Confirmer
                </button>
                    </div>
                )}
            </div>
            </LocalizationProvider>
        );
    }

export default HeuresTachePost