import { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import DomainIcon from '@mui/icons-material/Domain'
import ConstructionIcon from '@mui/icons-material/Construction'
import LogoutIcon from '@mui/icons-material/Logout'
import { AuthContext } from '../../context/AuthContext'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

import './bottomBar.scss'

const BottomBar = () => {
  const location = useLocation()
  const [openDialog, setOpenDialog] = useState(false)
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  // Pour Delogue + Confirmation
  const handleLogout = () => {
    logout()
    // Efface l'utilisateur du local storage
    localStorage.removeItem('utilisateur')
    navigate('/')
  }

  return (
    <div className="bottom-bar">
      <Link to="/">
        <div
          className={`icon-container ${
            location.pathname === '/' ? 'active' : ''
          }`}
        >
          <i className="iconhome">
            <HomeIcon className="icon" />
          </i>
        </div>
      </Link>
      <Link to="/affaires">
        <div
          className={`icon-container ${
            location.pathname === '/affaires' ? 'active' : ''
          }`}
        >
          <i className="iconaffaire">
            <DomainIcon className="icon" />
          </i>
        </div>
      </Link>
      <div className="icon-container">
        <i className="iconwork">
          <ConstructionIcon className="icon" />
        </i>
      </div>

      <div className="icon-container" onClick={() => setOpenDialog(true)}>
        <i className="iconlogout">
          <LogoutIcon className="icon" />
        </i>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{'Confirmer la Déconnexion'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir vous déconnecter ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Non
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BottomBar
