import { useContext, useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Close from '@mui/icons-material/Close'
import Send from '@mui/icons-material/Send'
import { format, isBefore } from 'date-fns'

import './file.scss'
import classNames from 'classnames'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'

const roleToText = {
  ADMIN: 'Admin',
  OUVRIER_TERRAIN: 'Ouvrier terrain'
}

function Comment ({ content, author, createdAt, me }) {
  return <div className={classNames('comment', me ? 'mine' : '')}>
    <div className='buble'>{content}</div>
    <div className='infos'>Par {author} le {format(new Date(createdAt), 'dd/MM/yyyy')}</div>
  </div>
}

const statusToText = {
  WAITING: 'En attente',
  IN_PROGRESS: 'En cours',
  VALIDATED: 'Validé',
  CONFIRMED: 'Confirmé',
  ARCHIVED: 'Archivé'
}

const File = ({ open, onClose, file }) => {
  const { utilisateur } = useContext(AuthContext)
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])

  const submit = () => {
    axios.post(`files/${file._id}/comments`, { content: comment }).then(res => {
      setComments(prev => [res.data, ...prev])
      setComment('')
    })
  }

  useEffect(() => {
    if (file) {
      axios.get(`files/${file._id}/comments`).then(res => {
        setComments(res.data.sort((a, b) => isBefore(a.createdAt, b.className) ? 1 : -1))
      })
    } else {
      setComments([])
      setComment('')
    }
  }, [file])

  return <Dialog
    open={open || false}
    onClose={onClose}
    fullWidth={true}
  >
    <Close onClick={onClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
    <DialogTitle>Fichier {file?.name}</DialogTitle>
    <DialogContent>
      <div className="info">
        <div><span className="title">{"Date d'expiration :"}</span> <span>{file?.expirationDate ? format(new Date(file?.expirationDate), 'dd/MM/yyyy') : ''}</span></div>
        <div><span className="title">Status :</span> <span>{file?.status ? statusToText[file.status] : ''}</span></div>
        <div><span className="title">Accès :</span> <span>{file?.access?.length ? file.access.map(a => roleToText[a]).join(', ') : ''}</span></div>
        <div><span className="title">Créé le :</span> <span>{file?.createdAt ? format(new Date(file?.createdAt), 'dd/MM/yyyy') : ''}</span></div>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
      <div className='comments'>
        <div className='title'>Commentaires</div>
        <div className='content'>
          {comments.length ? comments.map(comment => <Comment key={comment.id} content={comment.content} author={`${comment.author.prenom} ${comment.author.nom}`} createdAt={comment.createdAt} me={utilisateur._id === comment.author._id} />) : <span className='noComment'>💬 Pas encore de commentaire</span>}
        </div>
        <FormControl fullWidth variant="standard" style={{ margin: '10px 0px' }}>
          <Input
            fullWidth
            id="comment-label"
            placeholder='Commentaire'
            value={comment}
            onChange={e => setComment(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={submit}
                  disabled={!comment}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 24px' }}>
      <a href={file?.url} target='_blank' rel="noreferrer">
        <Button variant="contained">Ouvrir le fichier</Button>
      </a>
    </DialogActions>
  </Dialog>
}

export default File
