import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './assignMyTache.scss'

const AssignMyTache = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [tache, setTache] = useState(null)
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])
  // const [rapport, setRapport] = useState(null)
  const tacheId = location.state?.tacheId
  const rapportId = location.state?.rapportId
  const [originRoute, setOriginRoute] = useState('/definemytache')

  useEffect(() => {
    console.log('State from location:', location.state)
    setOriginRoute(location.state?.originRoute || '/definemytache')
  }, [location.state])

  useEffect(() => {
    // Fetch la tache sélectionnée
    const fetchTache = async () => {
      const response = await axios.get(`/taches/${tacheId}`)
      setTache(response.data)
    }
    fetchTache()

    console.log('Origin route3:', originRoute)
    // Fetch les membres de l'équipe depuis le rapport / id utilisateur puis retourne chercher le nom
    // Fetch les membres de l'équipe et les assignations existantes
    const fetchTeamMembersAndAssignments = async () => {
      const rapportResponse = await axios.get(`/rapports/${rapportId}`)
      const teamMemberIds = rapportResponse.data.teamMembers
      // setRapport(rapportResponse.data)
      // Récupération des détails complets de chaque membre de l'équipe
      const teamMemberDetails = await Promise.all(
        teamMemberIds.map(id => axios.get(`/utilisateurs/${id}`))
      )
      // Séparation des membres assignés et non assignés
      const assignedMembersIds = rapportResponse.data.tachesDay.find(tache => tache.tacheIdDay === tacheId)?.assignesTacheDay || []
      const [assignedMembers, unassignedMembers] = teamMemberDetails.reduce((acc, member) => {
        (assignedMembersIds.includes(member.data._id) ? acc[0] : acc[1]).push(member.data)
        return acc
      }, [[], []])

      setSelectedMembers(assignedMembers)
      setTeamMembers(unassignedMembers)
    }
    fetchTeamMembersAndAssignments()
  }, [tacheId, rapportId])

  const handleSelectMember = (member) => {
    if (!selectedMembers.some(selected => selected._id === member._id)) {
      setSelectedMembers(prev => [...prev, member])
      setTeamMembers(prev => prev.filter(m => m._id !== member._id))
    }
  }

  const handleDeleteMember = (memberId) => {
    // Ajoutez le membre à nouveau dans la liste des membres de l'équipe
    const memberToAddBack = selectedMembers.find(m => m._id === memberId)
    setTeamMembers(prev => [...prev, memberToAddBack])
    // Supprimez le membre de la liste des membres sélectionnés
    setSelectedMembers(prev => prev.filter(m => m._id !== memberId))
  }
  console.log('Origin route4:', originRoute)
  const handleValidateTache = async () => {
    if (!rapportId || !tacheId || selectedMembers.length === 0) {
      console.error('Informations manquantes')
      return
    }

    try {
      // Récupérer le rapport actuel pour obtenir les tachesDay existantes
      const currentRapportResponse = await axios.get(`/rapports/${rapportId}`)
      const currentTachesDay = currentRapportResponse.data.tachesDay

      // Trouver si la tâche actuelle est déjà dans tachesDay
      const existingTacheIndex = currentTachesDay.findIndex(tache => tache.tacheIdDay === tacheId)

      if (existingTacheIndex !== -1) {
        // Mettre à jour les assignés pour la tâche existante
        currentTachesDay[existingTacheIndex].assignesTacheDay = selectedMembers.map(member => member._id)
      } else {
        // Ajouter la nouvelle tâche et ses assignés
        const newTacheData = {
          tacheIdDay: tacheId,
          assignesTacheDay: selectedMembers.map(member => member._id)
        }
        currentTachesDay.push(newTacheData)
      }

      // Envoyer la requête PUT avec les données mises à jour
      const response = await axios.put(`/rapports/${rapportId}`, { tachesDay: currentTachesDay })
      console.log(response.data) // Log pour vérification
      if (originRoute === '/changemytacheselect') {
        const dataForChrono = {
          tache,
          selectedMembers,
          rapportId
        }
        navigate('/confirmandstartchrono', { state: dataForChrono })
      } else {
        navigate('/definemytache', { state: { rapportId } })
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données de l\'affaire', error)
    }
  }

  return (
    <div className="assignMyTache">
      <div className="headerassignMyTache">
      <button onClick={() => navigate(-1)}>Retour</button>
      <h1>Choisissez les personnes qui seront sur cette mission</h1>
      </div>
      titletachassignMyTache <div className="titletachassignMyTache">
      <h2>{tache?.nomTache}</h2>
      </div>
      <div className="selectedMembers">
        {selectedMembers.map((member) => (
          <div key={member._id} className="listassignMyTache">
            {member.nom}
            <button className="btnassignMyTache" onClick={() => handleDeleteMember(member._id)}>Supprimer</button>
          </div>
        ))}
      </div>
      <div className="teamMembersassignMyTache">
        {teamMembers.map((member) => (
          <div key={member._id} onClick={() => handleSelectMember(member)} className="list2assignMyTache">
            {member.nom}
          </div>
        ))}
      </div>
      {selectedMembers.length > 0 && (
        <button className="btn2assignMyTache" onClick={handleValidateTache}>Valider la Tâche</button>
      )}
    </div>
  )
}

export default AssignMyTache
