import { useState } from 'react'
import SelectAffaire from '../selectAffaire/SelectAffaire'
import CreateAffaire from '../createAffaire/CreateAffaire'
import EditTache from '../editTache/EditTache'
import ReviewAffaire from '../reviewAffaire/ReviewAffaire'
import SelectOrCreateAffaire from '../selectOrCreateAffaire/SelectOrCreateAffaire'

import './workflow.scss'
import ListTache from '../listTache/ListTache'
import { Link } from 'react-router-dom'

const Workflow = () => {
  const [step, setStep] = useState('selectOrCreateAffaire')
  const [selectedAffaire, setSelectedAffaire] = useState(null)
  const [taches/*, setTaches */] = useState([])
  // const [history, setHistory] = useState(['selectOrCreateAffaire']) // Historique des étapes
  // const [selectAffaire, setSelectAffaire] = useState(false)
  const [tacheToEdit, setTacheToEdit] = useState(null)

  const updateStep = (newStep) => {
    // setHistory(prev => [...prev, newStep]) // Ajoute la nouvelle étape à l'historique
    setStep(newStep)
  }

  // Fonctions pour gérer les transitions entre les étapes

  const handleSelectAffaire = () => {
    // Modifier pour permettre de choisir une affaire existante
    // setSelectAffaire(true)
    updateStep('selectAffaire')
  }

  const handleCreateAffaire = (affaire) => {
    setSelectedAffaire(affaire)
    updateStep('createTache')
  }

  const handleAffaireSelected = (affaire) => {
    console.log('Affaire reçue dans Workflow:', affaire)
    setSelectedAffaire(affaire)
    updateStep('listTache')
  }

  const handleCreateTacheClick = () => {
    updateStep('createTache')
  }

  const returnToListTache = () => {
    setStep('listTache')
  }

  const handleEditTacheSelected = (tache) => {
    setTacheToEdit(tache) // Stocke directement la tâche sélectionnée pour l'édition
    updateStep('editTache')
  }

  const handleReviewComplete = () => {
    // Logique pour finaliser l'affaire
    updateStep('finished')
  }

  // Boutton retour

  const handleBack = () => {
    // setHistory(prev => {
    //   const newHistory = prev.slice(0, prev.length - 1) // Enlève la dernière étape
    //   setStep(newHistory[newHistory.length - 1] || 'selectOrCreateAffaire') // Revient à l'étape précédente
    //   return newHistory
    // })
  }

  console.log('step', step)

  const renderStep = () => {
    switch (step) {
      case 'selectOrCreateAffaire':
        return (
          <SelectOrCreateAffaire
            onSelectAffaire={handleSelectAffaire}
            onCreateAffaire={handleCreateAffaire}
          />
        )
      case 'createAffaire':
        return <CreateAffaire onCreateAffaire={handleCreateAffaire} />
      case 'selectAffaire':
        return <SelectAffaire onSelectAffaire={handleAffaireSelected} />
      case 'listTache':
        return <ListTache affaireId={selectedAffaire?._id} onEditTacheSelected={handleEditTacheSelected} onCreateTacheClick={handleCreateTacheClick} />
      case 'createTache':
        return <EditTache affaireId={selectedAffaire?._id} onReturnToListTache={returnToListTache}/>
      case 'editTache':
        return <EditTache selectedTache={tacheToEdit} affaireId={selectedAffaire?._id} onReturnToListTache={returnToListTache}/>
      case 'reviewAffaire':
        return (
          <ReviewAffaire
            affaire={selectedAffaire}
            taches={taches}
            onReviewComplete={handleReviewComplete}
          />
        )
      case 'finished':
        return <div>Enregistrement terminé</div>
      default:
        return <div>Étape inconnue</div>
    }
  }

  return (
    <div className="Workflow">
        <div className="btn-top-workflow">
      {step !== 'selectOrCreateAffaire' && (
        <button onClick={handleBack}>Retour</button>
      )}
       <Link to="/"><a>Accueil</a> </Link>
       </div>
       <div className="contain-worflow">
      {renderStep()}
      </div>
    </div>
  )
}

export default Workflow
