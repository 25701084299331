import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './homeNew.scss';
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu';
import ItemClick from '../../Components/itemClick/ItemClick';
import AffichageHeuresTacheId from '../../Components/rappordayNew/affichageHeuresTacheId/AffichageHeuresTacheId';
import { useNavigate } from 'react-router-dom';

const HomeNew = () => {
    const { utilisateur } = useContext(AuthContext);
    const [affaires, setAffaires] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedTache, setSelectedTache] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date()); 
    const [openDescription, setOpenDescription] = useState(false);


    useEffect(() => {
        fetchTachesAffectees();
    }, []);

    const fetchTachesAffectees = async () => {
        const todayStr = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`/taches/affectees/phone/${utilisateur._id}`);
        const tachesAffectees = response.data;
        console.log(tachesAffectees)
        const affairesMap = {};
      
        tachesAffectees.forEach(tache => {
            tache.datesAffectation?.forEach(date => {
                const dateStr = new Date(date).toISOString().slice(0, 10);
                if (dateStr === todayStr && tache.typeTache === "Chantier") {
                    if (!affairesMap[tache.affaireId]) {
                        affairesMap[tache.affaireId] = {
                            nomAffaire: tache.nomAffaire,
                            _id: tache.affaireId,
                            description: tache.descriptionAffaire,
                            tachesChantier: [],
                            tachesAPrendre: [],
                        };
                    }
                    affairesMap[tache.affaireId].tachesChantier.push(tache);
                }
            });
        });

        // Pour chaque affaire avec des tâches chantier, récupérer les tâches à prendre correspondantes
        Object.keys(affairesMap).forEach(affaireId => {
            const tachesAPrendre = tachesAffectees.filter(tache =>
                tache.typeTache === "A Prendre" && tache.affaireId === affaireId
            );
            affairesMap[affaireId].tachesAPrendre = tachesAPrendre;
        });

        setAffaires(Object.values(affairesMap));
    };

        // Définir handleClose
        const handleClose = async () => {
            await fetchTachesAffectees();
            setOpen(false);
            setSelectedId(null);
            setSelectedTache(null);
          };

              // Définir handleOpen pour ouvrir le dialogue avec l'ID sélectionné
    const handleOpenForTacheDetails = (tache) => {
        const updatedTache = affaires.find(affaire => affaire.tachesChantier.some(tache => tache._id === tache._id))?.tachesChantier.find(tache => tache._id === tache._id);
        setSelectedTache(updatedTache); // Met à jour l'état avec la tâche fraîchement récupérée
        setOpen(true);
        };

      const handleOpenForTacheAPrendre = (id) => {
        setSelectedId(id);
        setOpen(true);
      };

      // Définir handleOpenForDescription pour Description Dialog
        const handleOpenForDescription = (tache) => {
            setSelectedTache(tache);
            setOpenDescription(true);
        };

      console.log("1",affaires)

      //clique affaire pour aller sur la page de chat
      const navigate = useNavigate();
      const handleNavigateToChat = (affaireId) => {
        navigate(`/messagerie/affaire/${affaireId}`);
    };
    return (
        <div className="home-page-new">
            <HamburgerMenu />
            <div className="contain-home-page-new">
                <div className="top-home-page-new">
                    <div className="titlehome">
                        <h1>Bienvenue </h1>
                        <h2>{utilisateur?.prenom}</h2>
                    </div>
                    <h2>Aujourd'hui le {new Date().toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })}</h2>
                </div>
                <div className="map-home-page-new">
                {affaires.length > 0 ? affaires.map(affaire => (
                    <div key={affaire.nomAffaire} className="affaire-block">
                        <h3 onClick={() => handleNavigateToChat(affaire._id)} >Affaire: {affaire.nomAffaire}</h3>
                        {affaire.tachesChantier.map(tache => (
                            <div key={tache._id} className='item-chantier-page-new'>
                                <p onClick={() => handleOpenForTacheDetails(tache)}>{tache.nomTache}</p>
                                {tache.description && <span onClick={() => handleOpenForDescription(tache)}>*</span>}
                            </div>
                        ))}
                        {affaire.tachesAPrendre.length > 0 && <h4>A prendre:</h4>}
                        {affaire.tachesAPrendre.map(tache => (
                            <div key={tache._id} className='item-aprendre-page-new'>
                            <p onClick={() => handleOpenForTacheAPrendre(tache._id)}>{tache.nomTache}</p>
                            {tache.description && <span>*</span>}
                        </div>
                        ))}
                    </div>
                )) : <p>Aucune tâche affectée pour aujourd'hui.</p>}
                </div>
            </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title">Options de la tâche</DialogTitle>
            <DialogContent>
            {selectedTache ? (
                <>
                    <h3>{selectedTache.nomTache}</h3>
                    <AffichageHeuresTacheId tache={selectedTache} date={selectedDate}  utilisateur={utilisateur} onClose={handleClose}/>
                <Button
                    onClick={() => {
                    navigate(`/documents/${selectedTache.affaireId}`); // Utilisez navigate pour la redirection
                    handleClose();
                    }}
                >
                    Document Affaire
                </Button>
                </>
            ) : (
                selectedId && <ItemClick idItem={selectedId} />
            )}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openDescription} onClose={() => setOpenDescription(false)}>
                <DialogTitle>{selectedTache?.nomTache}</DialogTitle>
                <DialogContent>
                    {selectedTache && <p>Description: {selectedTache.description}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDescription(false)}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default HomeNew;
