import { useEffect, useMemo, useState } from 'react'
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu'
import { useParams, useNavigate } from 'react-router-dom'
import './documents.scss'
import axios from 'axios'
import { ArrowBackIosNew, ArrowForwardIos, Folder, InsertDriveFile } from '@mui/icons-material'
// import Badge from '@mui/material/Badge'
// import CreateFolder from './components/createFolder'
import CreateFile from './components/createFile'
import File from './components/file'
import classNames from 'classnames'
import { addDays, isBefore } from 'date-fns'
import useFetch from '../../Hooks/useFetch'

// const statusToColor = {
//   WAITING: 'warning',
//   IN_PROGRESS: 'info',
//   VALIDATED: 'success',
//   CONFIRMED: 'success',
//   ARCHIVED: 'error'
// }

// const statusToText = {
//   WAITING: 'En attente',
//   IN_PROGRESS: 'En cours',
//   VALIDATED: 'Validé',
//   CONFIRMED: 'Confirmé',
//   ARCHIVED: 'Archivé'
// }

const AffairesDocument = () => {
  const { affaireId } = useParams()
  const { data: affaireData } = useFetch(`/affaires/docsandcomments/${affaireId}`)
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/affaires')
  }

  const [selectedFile, setSelectedFile] = useState()

  const [currentPath, setCurrentPath] = useState([])
  const currentFolderId = useMemo(() => currentPath[currentPath.length - 1]?.id || undefined, [currentPath])

  const openFolder = (file) => {
    setCurrentPath(prev => [...prev, file])
  }

  const backFolder = (fileId) => {
    setCurrentPath(prev => fileId ? prev.slice(0, prev.findIndex(file => file.id === fileId) - prev.length + 1) : [])
  }

  const prevFolder = () => {
    setCurrentPath(prev => prev.slice(0, -1))
  }

  const [files, setFiles] = useState([])
  const sortedFiles = useMemo(() => files
    .filter(a => !a.access.length || a.access.includes('OUVRIER_TERRAIN'))
    .sort((a, b) => a.folder === b.folder ? a.name < b.name ? -1 : 1 : a.folder ? -1 : 1)
  , [files])

  const addFile = (newFile) => {
    setFiles(prev => [...prev, newFile])
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(currentFolderId ? `/files/${currentFolderId}/children` : `/affaires/${affaireId}/files`)
        setFiles(res.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des documents', error)
      }
    })()
  }, [currentPath])

  return (
    <div className="affaireDocuments">
      <div className='top-affaireDocuments'>
        <HamburgerMenu />
        <button onClick={handleBackClick} className="btn-retour">Retour</button>
      </div>
      <div className="header-affaireDocuments" style={{ marginTop: 15 }}>
        <div className="path-affaireDocuments">
          <div key='path-origin' onClick={currentPath.length ? backFolder : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            <span>{"Documents de l'affaire"} {affaireData?.nomAffaire}</span>
            {currentPath.length ? <ArrowForwardIos className='icon-affaireDocuments'/> : null}
          </div>
          {currentPath.map((file, fileIndex) => <div key={`path-${file.id}`} onClick={currentPath.length !== fileIndex + 1 ? () => backFolder(file.id) : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            <span>{file.name}</span>
            {currentPath.length !== fileIndex + 1 ? <ArrowForwardIos className='icon-affaireDocuments'/> : null}
          </div>)}
        </div>
        <div className="actions-affaireDocuments">
          {/* <CreateFolder affaireId={affaireId} currentFolderId={currentFolderId} callback={addFile} /> */}
          <CreateFile affaireId={affaireId} currentFolderId={currentFolderId} callback={addFile} />
          <File />
        </div>
      </div>
      <div className="content-affaireDocuments" key={`folder-${currentFolderId || 'origin'}`}>
        {currentPath.length
          ? <a onClick={prevFolder} href={undefined} target={undefined}>
              <div className='item select-none back' >
                <ArrowBackIosNew  className='icon-back-affaireDocuments'/>
              </div>
            </a>
          : null
          }
        {sortedFiles
        // <a key={file.id} onClick={file.folder ? () => openFolder(file) : undefined} href={file.folder ? undefined : file.url} target={file.folder ? undefined : '_blank'} rel="noreferrer">
          .map(file => <div key={file.id} onClick={file.folder ? () => openFolder(file) : () => setSelectedFile(file)}>
            <div
              className={classNames('item select-none',
                file.expirationDate && isBefore(new Date(file.expirationDate), new Date())
                  ? 'expired'
                  : file.expirationDate && isBefore(new Date(file.expirationDate), addDays(new Date(), 7))
                    ? 'expiringSoon'
                    : ''
              )}
            >
              {/* {file.status ? <Badge style={{ fontSize: '8px', position: 'absolute', right: 0 }} color={statusToColor[file.status]}>{statusToText[file.status]}</Badge> : null} */}
              {file.folder ? <Folder  className='icon-affaireDocuments'/> : file.type.includes('image') ? <img src={file.url} /> : <InsertDriveFile  className='icon-affaireDocuments' />}
              <div className="name">{file.name}</div>
            </div>
        </div>)}
      </div>
      <File open={!!selectedFile} onClose={() => setSelectedFile(undefined)} file={selectedFile} />
    </div>
  )
}

export default AffairesDocument
