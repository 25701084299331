import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './btnEndMyDay.scss'

const BtnEndMyDay = ({ rapport }) => {
  const navigate = useNavigate()

  const handleEndDay = async () => {
    try {
      const fin = new Date() // Heure actuelle pour marquer la fin de la journée

      // Récupérer le rapport actuel pour accéder aux heures d'arrivée existantes
      const currentRapportResponse = await axios.get(
        `/rapports/${rapport._id}`
      )
      const currentRapport = currentRapportResponse.data

      // Préparation des mises à jour des heures pour chaque utilisateur
      const teamMemberHoursUpdates = currentRapport.teamMemberHours.map(
        (hour) => ({
          ...hour, // Conserver les données existantes
          depart: hour.depart ? hour.depart : fin.toISOString() // Mettre à jour uniquement l'heure de départ
        })
      )

      // Récupérer les tâches associées à l'affaire
      console.log(
        `Récupération des tâches pour l'affaire ID: ${rapport.affaireId}`
      )
      const tachesResponse = await axios.get(
        `/taches/byAffaire/${rapport.affaireId}`
      )
      const taches = tachesResponse.data
      console.log('Tâches récupérées:', taches)

      // Calculer le pourcentage global de réalisation de l'affaire
      let totalPourcentage = 0
      let totalDuree = 0
      taches.forEach(tache => {
        if (tache.typeTache === 'Chantier' && tache.duration != null) {
          console.log(`Traitement de la tâche: ${tache._id}, Pourcentage de réalisation: ${tache.pourcentageRealisation}, Durée: ${tache.duration}`)
          totalPourcentage += tache.pourcentageRealisation * tache.duration
          totalDuree += tache.duration
        }
      })
      const pourcentageRealisationAffaire = totalDuree > 0 ? totalPourcentage / totalDuree : 0
      console.log(`Pourcentage global de réalisation de l'affaire: ${pourcentageRealisationAffaire}`)

      // Mise à jour des chronomètres pour chaque tâche
      for (const tacheDay of currentRapport.tachesDay) {
        for (const chronoId of tacheDay.chronometres) {
          const chronoResponse = await axios.get(`/chronometres/${chronoId}`)
          const debut = new Date(chronoResponse.data.debut)
          const duree = (fin - debut) / 1000 // Durée en secondes

          // Mise à jour du chronomètre avec fin et durée
          await axios.put(`/chronometres/${chronoId}`, {
            fin: fin.toISOString(),
            duree
          })
        }
      }

      // Mise à jour du rapport avec les heures mises à jour et marquage comme inactif
      await axios.put(`/rapports/${rapport._id}`, {
        actif: false,
        teamMemberHours: teamMemberHoursUpdates,
        pourcentageRealisation: pourcentageRealisationAffaire
      })

      // Mettre à jour l'affaire
      await axios.put(`/affaires/phoneEnd/${rapport.affaireId}`, {
        pourcentageRealisation: pourcentageRealisationAffaire,
        $push: { rapportsFinDeJournee: rapport._id }
      })

      // Ajouter l'ID du rapport dans les rapportsInactifs de la table DataDay
      await axios.put(`/dataday/${rapport.dataDayId}`, {
        rapportsInactifs: [rapport._id]
      })

      navigate('/') // Naviguer vers la page d'accueil
    } catch (error) {
      console.error('Erreur lors de la fin de la journée', error)
    }
  }

  return (
    <div className="btn-end-my-day">
      <button onClick={handleEndDay}>Terminer ma Journée</button>
    </div>
  )
}

export default BtnEndMyDay
