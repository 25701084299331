import { Link } from 'react-router-dom'
import { useState } from 'react'
import { TextField, Checkbox, FormControlLabel } from '@mui/material'
import './ajoutBl.scss'

const AjoutBl = ({ rapport }) => {
  const [transporteur, setTransporteur] = useState('')
  const [articlesRecus, setArticlesRecus] = useState('')
  const [quantiteOk, setQuantiteOk] = useState(false)
  const [etatOk, setEtatOk] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const bonDeLivraison = {
      transporteur,
      articlesRecus,
      quantiteOk,
      etatOk
    }
    console.log(bonDeLivraison)
    // Ici, vous pouvez faire un appel API pour envoyer ces informations
  }

  const handleCapture = (e) => {
    // Gérer la capture de l'image
    const file = e.target.files[0]
    if (file) {
      // Vous pouvez traiter le fichier ici, par exemple l'envoyer à un serveur ou l'afficher dans l'interface utilisateur
      console.log(file)
    }
  }

  return (
    <div className="ajout-bl">
        <div className="btn-retour-ajout-bl">
      <Link to="/" style={{ textDecoration: 'none' }}>
        <button className="btn-AjoutBl">Retour</button>
      </Link>
      </div>
      <h3>Ajouter un Bon de Livraison</h3>

      <form onSubmit={handleSubmit}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="icon-button-file"
          type="file"
          capture="environment" // Spécifie que l'appareil photo doit être utilisé
          onChange={handleCapture}
        />
        <label htmlFor="icon-button-file">
          <button className="btn-AjoutBl">
            Prendre le BL en photo
          </button>
        </label>

        <TextField
          label="Transporteur"
          value={transporteur}
          onChange={(e) => setTransporteur(e.target.value)}
          fullWidth
        />
        <TextField
          label="Articles Réceptionnés"
          value={articlesRecus}
          onChange={(e) => setArticlesRecus(e.target.value)}
          fullWidth
          multiline
          rows={4}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={quantiteOk}
              onChange={(e) => setQuantiteOk(e.target.checked)}
            />
          }
          label="Quantité Conforme"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={etatOk}
              onChange={(e) => setEtatOk(e.target.checked)}
            />
          }
          label="État des Articles Conforme"
        />
        <button type="submit" className="btn-AjoutBl">
          Enregistrer le Bon de Livraison
        </button>
      </form>
    </div>
  )
}

export default AjoutBl
