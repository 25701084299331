import { useState } from 'react'
import { Link } from 'react-router-dom'
import SelectAffaire from '../../btnNewAffaireTs/selectAffaire/SelectAffaire'
import ChoixActionsRapport from '../ChoixActionsRapport/ChoixActionsRapport'

import './creationRapportDeVisite.scss'
import ReunionRapportVisite from '../ReunionRapportVisite/ReunionRapportVisite'
import RapportChantierGeneral from '../RapportChantierGeneral/RapportChantierGeneral'
import RapportTachesChantier from '../RapportTachesChantier/RapportTachesChantier'
import AmeliorationRapportVisite from '../AmeliorationRapportVisite/AmeliorationRapportVisite'
import ChoixRapportDeVisite from '../ChoixRapportDeVisite/ChoixRapportDeVisite'

const CreationRapportDeVisite = () => {
  const [step, setStep] = useState('selectAffaire')
  // const [history, setHistory] = useState(['selectAffaire'])
  const [selectedAffaire, setSelectedAffaire] = useState(null)
  const [rapportDeVisite, setRapportDeVisite] = useState(null)

  const updateStep = (newStep) => {
    // setHistory(prev => [...prev, newStep]) // Ajoute la nouvelle étape à l'historique
    setStep(newStep)
  }

  // Fonctions pour gérer les transitions entre les étapes
  // Choix de l'affaire
  const handleAffaireSelected = (affaire) => {
    setSelectedAffaire(affaire)
    updateStep('choixRapport')
  }

  // Choix du rapport
  const handleRapportChange = (rapport) => {
    setRapportDeVisite(rapport)
    updateStep('choixActions')
    // Définir ici la logique pour 'choixActions' ou d'autres étapes suivantes
  }

  // Choix des actions
  const handleActionSelected = (action) => {
    // Mettre à jour l'étape en fonction de l'action sélectionnée
    updateStep(action)
  }

  // Modifier les datas du rapport
  const handleUpdateRapport = (newData) => {
    setRapportDeVisite(newData)
  }

  // Boutton retour
  const handleBack = () => {
    // setHistory(prev => {
    //   const newHistory = prev.slice(0, prev.length - 1)
    //   const newStep = newHistory[newHistory.length - 1] || 'selectOrCreateAffaire'
    //   setStep(newStep)
    //   return newHistory
    // })
  }

  console.log('step', step)
  console.log('rapportDeVisite', rapportDeVisite)

  const renderStep = () => {
    switch (step) {
      case 'selectAffaire':
        return <SelectAffaire onSelectAffaire={handleAffaireSelected} />
      case 'choixRapport':
        return <ChoixRapportDeVisite affaire={selectedAffaire} onRapportChange={handleRapportChange} />
      case 'choixActions':
        return <ChoixActionsRapport onActionSelected={handleActionSelected} affaire={selectedAffaire} rapport={rapportDeVisite}/>
      case 'modifierReunion':
        return <ReunionRapportVisite affaire={selectedAffaire} rapport={rapportDeVisite} onBack={handleBack} onUpdateRapport={handleUpdateRapport}/>
      case 'rapportChantierGeneral':
        return <RapportChantierGeneral affaire={selectedAffaire} rapport={rapportDeVisite} onBack={handleBack} onUpdateRapport={handleUpdateRapport}/>
      case 'rapportTachesChantier':
        return <RapportTachesChantier affaire={selectedAffaire} rapport={rapportDeVisite} onBack={handleBack} onUpdateRapport={handleUpdateRapport}/>
      case 'amelioration':
        return <AmeliorationRapportVisite affaire={selectedAffaire} rapport={rapportDeVisite} onBack={handleBack} onUpdateRapport={handleUpdateRapport}/>

      default:
        return <div>Étape inconnue</div>
    }
  }

  return (
    <div className="CreationRapportDeVisite">
        <div className="btn-group-crea-rapport-visite">
              {step !== 'selectAffaire' && (
        <button onClick={handleBack}>Retour</button>
              )}
      <Link to="/"><button>Accueil</button></Link>
      </div>
      <div className="render-crea-rapport-visite">
      {renderStep()}
      </div>
      </div>
  )
}

export default CreationRapportDeVisite
