import useFetch from '../../../../Hooks/useFetch'

import './listTache.scss'

const ListTache = ({ affaireId, onEditTacheSelected, onCreateTacheClick }) => {
  const { data: taches, loading, error } = useFetch(`/affaires/taches/${affaireId}`)

  console.log("ID de l'affaire dans ListTache:", affaireId)
  if (loading) return <p>Chargement des tâches...</p>
  if (error) return <p>Erreur lors du chargement des tâches : {error}</p>

  return (
    <div className='ListTache'>
        <div className="title-ListTache">
      <h3>{"Tâches pour l'Affaire"} {affaireId}</h3>
      </div>
      {taches.length > 0
        ? (
        <ul className='ul-list-tache'>
          {taches.map(tache => (
            <li className='li-list-tache' key={tache._id} onClick={() => onEditTacheSelected(tache)}>
            {tache.nomTache}
            </li>
          ))}
        </ul>
          )
        : (
        <p>Aucune tâche trouvée pour cette affaire.</p>
          )}
            <button className='btn-list-tache' onClick={onCreateTacheClick}>Créer une Nouvelle Tâche</button>
    </div>
  )
}

export default ListTache
