import React, { useState, useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu';
import ItemClick from '../../Components/itemClick/ItemClick';
import { useNavigate } from 'react-router-dom'; 
import './calendrierAssignation.scss';
import AffichageHeuresTacheId from '../../Components/rappordayNew/affichageHeuresTacheId/AffichageHeuresTacheId';

const CalendrierAssignation = () => {
  const { utilisateur } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [markedDates, setMarkedDates] = useState([]);
  const [tachesDuJour, setTachesDuJour] = useState([]);
  const [tachesAPrendreDuJour, setTachesAPrendreDuJour] = useState([]);
  const [toutesLesTachesAffectees, setToutesLesTachesAffectees] = useState([]); // Nouvel état pour stocker toutes les tâches affectées
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [dateFormatee, setDateFormatee] = useState('');
  const [selectedTache, setSelectedTache] = useState(null);
  const navigate = useNavigate();
  const [unfilledHoursDates, setUnfilledHoursDates] = useState([]);

  useEffect(() => {
    const formatDate = selectedDate.toLocaleDateString('fr-FR', {
      weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
    });
    setDateFormatee(formatDate);
  }, [selectedDate]); 

  const fetchTachesAffectees = async () => {
    try {
      const response = await axios.get(`/taches/affectees/phone/${utilisateur._id}`);
      const tachesAffectees = response.data;
      let datesMarquees = [];
      let datesWithUnfilledHours = [];
  
      tachesAffectees.forEach(tache => {
        tache.datesAffectation?.forEach(date => {
          const dateString = new Date(date).toISOString().split('T')[0];
          datesMarquees.push(dateString);
  
          // Vérifiez si les heures ne sont pas remplies pour cette date
          const hasUnfilledHours = tache.affectations.some(affectation =>
            affectation.date.startsWith(dateString) &&
            affectation.chefs.concat(affectation.ouvriers).some(person =>
              person.heures === 0
            )
          );
  
          if (hasUnfilledHours) {
            datesWithUnfilledHours.push(dateString);
          }
        });
      });
  
      setMarkedDates([...new Set(datesMarquees)]);
      setUnfilledHoursDates([...new Set(datesWithUnfilledHours)]);
      setToutesLesTachesAffectees(tachesAffectees);
  
      if (selectedTache) {
        const updatedTache = tachesAffectees.find(t => t._id === selectedTache._id);
        if (updatedTache) {
          setSelectedTache(updatedTache);
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des tâches affectées", error);
    }
  };

    useEffect(() => {
      fetchTachesAffectees();
    }, [utilisateur._id]);


  // Fonction d'aide pour obtenir la chaîne de date locale
  const toLocalDateString = (date) => {
    // Crée une nouvelle date en tenant compte du décalage horaire pour la convertir correctement en chaîne locale
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0];
  };

  // Définir handleDayClick pour gérer le clic sur un jour
  const handleDayClick = (value) => {
    setSelectedDate(value);
  // Formattez la date ici pour s'assurer qu'elle est mise à jour immédiatement
  const newDateFormatee = value.toLocaleDateString('fr-FR', {
    weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
  });
  setDateFormatee(newDateFormatee);
  // Utilise la fonction d'aide pour obtenir la chaîne de date locale
    const selectedDateString = toLocalDateString(value);

     // Filtre les tâches basées sur la correspondance des dates d'affectation avec la date sélectionnée, en ignorant l'heure
     const tachesAffecteesPourJour = toutesLesTachesAffectees.filter(tache =>
        tache.datesAffectation?.some(date => 
          date.startsWith(selectedDateString)
        )
      );
      console.log("Tâches affectées pour le jour sélectionné:", tachesAffecteesPourJour);
      setTachesDuJour(tachesAffecteesPourJour);
  
    const affairesIdsPourJour = tachesAffecteesPourJour.map(tache => tache.affaireId);
    // Assurez-vous d'utiliser toutesLesTachesAffectees ici aussi
    const tachesAPrendrePourJour = toutesLesTachesAffectees.filter(tache =>
      tache.typeTache === "A Prendre" && affairesIdsPourJour.includes(tache.affaireId)
    );
    setTachesDuJour(tachesAffecteesPourJour);
    setTachesAPrendreDuJour(tachesAPrendrePourJour);
  };


    // Définir handleOpen pour ouvrir le dialogue avec l'ID sélectionné
    const handleOpenForTacheDetails = async (tache) => {
      await fetchTachesAffectees();  // Assurez-vous que les données sont à jour
      const updatedTache = toutesLesTachesAffectees.find(t => t._id === tache._id);
      setSelectedTache(updatedTache); // Met à jour l'état avec la tâche fraîchement récupérée
      setOpen(true);
    };
      
      const handleOpenForTacheAPrendre = (id) => {
        setSelectedId(id);
        setOpen(true);
      };
console.log("selectedTache", selectedTache)
        // Définir handleClose
        const handleClose = async () => {
          await fetchTachesAffectees();
          setOpen(false);
          setSelectedId(null);
          setSelectedTache(null);
        };


        // Définir tileClassName pour personnaliser les classes de tuile
        const tileClassName = ({ date, view }) => {
          if (view === 'month') {
            const dateString = toLocalDateString(date);
            let classes = [];
        
            if (markedDates.includes(dateString)) {
              classes.push('marked-date');
            }
        
            if (unfilledHoursDates.includes(dateString) && date < new Date()) {
              classes.push('attention-needed'); // Classe pour le point rouge si des heures ne sont pas remplies
            }
        
            return classes.join(' ');
          }
        
          return null;
        };

console.log("selectedDate", selectedDate)
  return (
    <div className="calendrier-assignation">
      <HamburgerMenu />
      <div className="calendrier-calendrier-assignation">
      <Calendar
        onClickDay={handleDayClick}
        value={selectedDate}
        locale="fr-FR"
        tileClassName={tileClassName}
      />
      <div className='render-tache-calendrier-assignation'>
      <h3 className='date-du-jour-render-tache'>{dateFormatee}</h3>
        {tachesDuJour.length > 0 ? tachesDuJour.map(tache => (
          <div key={tache._id} className='render-tache-solo' onClick={() => handleOpenForTacheDetails(tache)}>
            <h4 className='title-tache-solo'>{tache.nomTache}</h4>
            <p>Affaire: {tache.nomAffaire}</p>
          </div>
        )) : <p>Aucune tâche affectée pour cette journée.</p>}
        {tachesAPrendreDuJour.length > 0 && <h4>A Prendre :</h4>}
        {tachesAPrendreDuJour.map(tache => (
          <p className='item-click-calendrier-assignation' onClick={() => handleOpenForTacheAPrendre(tache._id)} key={tache._id}>{tache.nomTache}</p>
        ))}
      </div>
    </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Options de la tâche</DialogTitle>
        <DialogContent>
          {selectedTache ? (
            <>
                <h3>{selectedTache.nomTache}</h3>
                <AffichageHeuresTacheId tache={selectedTache} date={selectedDate}  utilisateur={utilisateur} onClose={handleClose}/>

              <Button
                onClick={() => {
                  navigate(`/documents/${selectedTache.affaireId}`); // Utilisez navigate pour la redirection
                  handleClose();
                }}
              >
                Document Affaire
              </Button>

            </>
          ) : (
            selectedId && <ItemClick idItem={selectedId} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CalendrierAssignation;
