import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './updateClocking.scss'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Close from '@mui/icons-material/Close'
import { addMinutes } from 'date-fns'

const UpdateClocking = ({ clocking, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [debut, setDebut] = useState(null)
  const [duree, setDuree] = useState(0)

  const [selectedAffaire, setSelectedAffaire] = useState('')
  const [selectedTache, setSelectedTache] = useState('')

  const handleClose = () => {
    setOpen(prev => !prev)
    setDuree(clocking.duree ? clocking.duree / 60 : 0)
    setDebut(new Date(clocking.debut))
    setSelectedAffaire(clocking.tacheId.affaireId._id)
    setSelectedTache(clocking.tacheId._id)
  }

  return <>
    <Button onClick={handleClose} color='primary' style={{ textTransform: 'none' }}>
      modifier
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault()
          setIsLoading(true)
          axios.put(`/chronometres/${clocking._id}/force`, {
            debut,
            duree: parseInt(duree) * 60,
            fin: addMinutes(new Date(debut), parseInt(duree))
          }).then((res) => {
            callback(prev => prev.map(p => p._id === res.data._id ? { ...p, ...res.data } : p))
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          }).finally(() => {
            setIsLoading(false)
          })
        }
      }}
      fullWidth={true}
    >
      <DialogTitle>Modifier le pointage</DialogTitle>
      <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent className="form">
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <InputLabel id="affaire-label">Affaire</InputLabel>
          <Select
            labelId="affaire-label"
            value={selectedAffaire}
            readOnly
            disabled
            input={<OutlinedInput label="Affaire" />}
          >
            <MenuItem value={clocking.tacheId.affaireId._id}>{clocking.tacheId.affaireId.nomAffaire}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <InputLabel id="tache-label">Tâche</InputLabel>
          <Select
            labelId="tache-label"
            value={selectedTache}
            readOnly
            disabled
            input={<OutlinedInput label="Tâche" />}
          >
            <MenuItem value={clocking.tacheId._id}>{clocking.tacheId.nomTache}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <DatePicker
            label="Date de début"
            value={debut}
            // onChange={v => setDebut(v)}
            readOnly
            disabled
          />
        </FormControl>
        <TextField
          type='number'
          min={0}
          margin="dense"
          label="Durée en minutes"
          fullWidth
          variant="outlined"
          value={duree}
          onChange={e => setDuree(e.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={isLoading}>{isLoading ? <CircularProgress /> : 'Modifier le pointage'}</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default UpdateClocking
