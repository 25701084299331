import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './createClocking.scss'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Close from '@mui/icons-material/Close'
import { addMinutes } from 'date-fns'

const CreateClocking = ({ callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [debut, setDebut] = useState(null)
  const [duree, setDuree] = useState(0)

  const [affaires, setAffaires] = useState([])
  const [selectedAffaire, setSelectedAffaire] = useState('')

  useEffect(() => {
    axios
      .get('/affaires')
      .then(res => setAffaires(res.data))
  }, [])

  const [taches, setTaches] = useState([])
  const [selectedTache, setSelectedTache] = useState('')
  useEffect(() => {
    setSelectedTache('')
    if (selectedAffaire?.length) {
      axios
        .get(`/taches/byAffaire/${selectedAffaire}`)
        .then(res => setTaches(res.data))
    } else {
      setTaches([])
    }
  }, [selectedAffaire])

  const handleClose = () => {
    setOpen(prev => !prev)
    setDuree(0)
    setDebut(null)
    setSelectedAffaire('')
    setSelectedTache('')
  }

  return <>
    <Button variant='contained' onClick={handleClose} color='primary'>
      Nouveau pointage
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault()
          setIsLoading(true)
          axios.post('/chronometres/force', {
            tacheId: selectedTache,
            affaireId: selectedAffaire,
            debut,
            duree: parseInt(duree) * 60,
            fin: addMinutes(new Date(debut), parseInt(duree))
          }).then((res) => {
            callback(prev => [res.data, ...prev])
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          }).finally(() => {
            setIsLoading(false)
          })
        }
      }}
      fullWidth={true}
    >
      <DialogTitle>Nouveau pointage</DialogTitle>
      <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent className="form">
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <InputLabel id="affaire-label">Affaire</InputLabel>
          <Select
            labelId="affaire-label"
            value={selectedAffaire}
            onChange={e => setSelectedAffaire(e.target.value)}
            input={<OutlinedInput label="Affaire" />}
          >
            {affaires.map(affaire => <MenuItem key={affaire._id} value={affaire._id}>{affaire.nomAffaire}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <InputLabel id="tache-label">Tâche</InputLabel>
          <Select
            labelId="tache-label"
            value={selectedTache}
            onChange={e => setSelectedTache(e.target.value)}
            input={<OutlinedInput label="Tâche" />}
          >
            {taches.map(tache => <MenuItem key={tache._id} value={tache._id}>{tache.nomTache}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 4 }}>
          <DatePicker
            label="Date de début"
            value={debut}
            onChange={v => setDebut(v)}
          />
        </FormControl>
        <TextField
          type='number'
          min={0}
          margin="dense"
          label="Durée en minutes"
          fullWidth
          variant="outlined"
          value={duree}
          onChange={e => setDuree(e.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={isLoading}>{isLoading ? <CircularProgress /> : 'Ajouter le pointage'}</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default CreateClocking
