import { useEffect, useState, useRef, useContext, useMemo } from 'react'
import axios from 'axios';
import { format, isBefore } from 'date-fns'
import { AuthContext } from '../../../context/AuthContext';
import { useFirebase } from '../../../context/Firebase';
import { CircularProgress } from '@mui/material'
import { MoreHoriz as MoreHorizIcon, Autorenew as AutorenewIcon, ArrowBackIosNew as ArrowBackIosNewIcon, Attachment as AttachmentIcon, Send as SendIcon, AddPhotoAlternate as AddPhotoAlternateIcon, Close as CloseIcon} from '@mui/icons-material'
import classNames from 'classnames'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { fileToKey } from '../../../utils/storage'
import './chatAffaire.scss'



const Message = ({ _id, sentBy, me, content, createdAt, file }) => {
    return <div className={classNames('message', me ? 'me' : '')}>
      {file && !content 
        ? file.type.includes('image')
          ? <a href={file.url} target='_blank'>
              <div className='image'>
                <img src={file.url} />
              </div>
            </a>
          : <a className='file' target='_blank' href={file.url} download={file.name}>{file.name}</a>
        : <div className='content'>{content}</div>
      }
      <div className='description'>{sentBy.prenom} {sentBy.nom} le {format(new Date(createdAt), 'dd/MM à HH:mm')}</div>
    </div>
  }
const ChatAffaire = ({ affaireId }) => {
    const { utilisateur } = useContext(AuthContext)
    const { storage } = useFirebase()
    const [messages, setMessages] = useState([]);
    const [activeChat, setActiveChat] = useState(null)  
    useEffect(() => {
        const fetchChatDetails = async () => {
          try {
            const response = await axios.get(`/messages/chatAffaire/${affaireId}`);
            setActiveChat(response.data); // Set activeChat with fetched chat details
            setMessages(response.data.messages || []); // Assuming that chat details include messages
          } catch (error) {
            console.error('Failed to fetch chat details:', error);
          }
        };
    
        fetchChatDetails();
      }, [affaireId]);
      const [chats, setChats] = useState([])
      useEffect(() => {
        axios.get('/messages/chats').then((res) => {
          setChats(res.data)
          if (document.body.clientWidth >= 800 && !activeChat){
            const tmpSorted = chats.sort((c1, c2) => !c1?.lastMessage?.createdAt ? 1 : !c2?.lastMessage?.createdAt ? -1 : isBefore(new Date(c1?.lastMessage?.createdAt), new Date(c2?.lastMessage?.createdAt)) ? 1 : -1)
            if (tmpSorted.length){
              setActiveChat(tmpSorted[0])
            }
          }
        })
      }, [])


    const [messagesLoading, setMessagesLoading] = useState(false)
    const sortedMessages = useMemo(() => messages.sort((m1, m2) => isBefore(new Date(m1.createdAt), new Date(m2.createdAt)) ? 1 : -1), [messages])

    const getMessages = async (nonewtimeout) => {
        if (!activeChat) {
          setMessages([])
          return
        }
        if (!nonewtimeout){
          setTimeout(getMessages, 300000)
        }
        setMessagesLoading(true)
        const res = await axios.get(`/messages`, { params: activeChat })
        setMessages(res?.data || [])
        setMessagesLoading(false)
      }
    
      useEffect(() => {
        getMessages()
      }, [activeChat])


      const inputFile = useRef(null) 
      const [message, setMessage] = useState('')
      const [selectedFile, setSelectedFile] = useState(null)
      const send = async () => {
        if (!message.trim().length && !selectedFile) return
        const fileUrl = selectedFile ? await fileToKey(selectedFile, storage) : undefined
        axios.post('/messages', {
          content: selectedFile ? undefined : message.trim(),
          file: fileUrl ? {
            name: selectedFile.name,
            url: fileUrl,
            type: selectedFile.type
          } : undefined,
          affaire: activeChat.affaire,
          members: activeChat.members
        })
        const newTmpMessage = {_id: `tmpid-${messages.length}`, sentBy: {_id: utilisateur._id, prenom: utilisateur.prenom, nom: utilisateur.nom}, file: selectedFile ? { url: selectedFile.preview, type: selectedFile.type, name: selectedFile.name } : undefined, content: selectedFile ? undefined : message.trim(), createdAt: new Date()}
        setMessages(prev => [...prev, newTmpMessage])
        setChats(prev => [...prev.map(c => ({...c, lastMessage: c._id === activeChat._id ? newTmpMessage : c.lastMessage}))])
        setSelectedFile(null)
        setMessage('')
      }

      const [anchorEl, setAnchorEl] = useState(null)
      const open = useMemo(() => Boolean(anchorEl), [anchorEl])
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
      };
      const handleClose = (e) => {
        e.stopPropagation()
        setAnchorEl(null)
      }
    
      const onKeyDown = (event) => {
        if (event.which === 13 && !event.shiftKey) {
            send()
            event.preventDefault();
            return false;
        }
      }
      const uploadFile = (onlyImage) => {
        inputFile.current.accept = onlyImage ? 'image/*' : '*'
        inputFile.current.click()
      }
      const selectFile = (e) => {
        const files = e.target.files
        setSelectedFile(Object.assign(files[0], {
          preview: URL.createObjectURL(files[0])
        }))
        setAnchorEl(null)
      }

    return (
        <div className='ChatAffaire'>
            <div className='chat'>
        <div className={classNames('content', activeChat ? 'selected' : '')} >
          {activeChat 
            ? <div className='header box'>
                <div className='title'>{activeChat.name}</div>
                <AutorenewIcon className='refresh' onClick={() => getMessages(true)} /> 
              </div>
            : null
          }
          <div className='messages box'>
            {messagesLoading 
              ? <CircularProgress className='loading' /> 
              : !activeChat 
                ? <div className='no-messages'>⬅️ Merci de sélectionner une conversation ⬅️</div> 
                : !sortedMessages?.length 
                  ? <div className='no-messages'>💬 Pas encore de message dans cette conversation 🤷‍♂️</div>
                  : sortedMessages.map(message => <Message key={message._id} {...message} me={utilisateur._id === message.sentBy._id} />)
            }
          </div>
          <div className='input box'>
            {selectedFile 
              ? <div className='selectedFiles'>
                  {selectedFile.type.includes('image') 
                    ? <img src={selectedFile.preview} />
                    : <span>{selectedFile.name}</span>
                  }
                  <CloseIcon className='close' onClick={() => setSelectedFile(null)} />
                </div>
              : <textarea placeholder='Votre message...' value={message} onChange={e => setMessage(e.currentTarget.value)} onKeyDown={onKeyDown} />
            }
            <div className='actions'>
              <div className='icon' onClick={send}>
                <SendIcon />
              </div>
              <div className='icon' onClick={handleClick}>
                <MoreHorizIcon />
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{ style: { marginLeft: -30, marginTop: -20 }, }}
                >
                  <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={selectFile} />
                  <MenuItem selected={false} onClick={() => uploadFile()}>
                    <AttachmentIcon style={{marginRight: '6px'}} /><span>Ajouter un fichier</span>
                  </MenuItem>
                  <MenuItem selected={false} onClick={() => uploadFile(true)}>
                    <AddPhotoAlternateIcon style={{marginRight: '6px'}} /><span>Ajouter une image</span>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
    );
};
export default ChatAffaire