import { useLocation, useNavigate } from 'react-router-dom'
import './chooseTheChange.scss'

const ChooseTheChange = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const rapport = location.state?.rapport

  const handleChangeMyTeam = () => {
    navigate('/changemyteam', { state: { rapport } })
  }
  const handleChangeMyTacheSelect = () => {
    navigate('/changemytacheselect', { state: { rapport } })
  }
  const handleChangeMyTache = () => {
    navigate('/changemyday', { state: { rapport } })
  }

  return (
    <div className="ChooseTheChange">
    <button className="btnretour" onClick={() => navigate('/', { state: { rapport } })}>Retour</button>
    <div className="titleChooseTheChange">
        <h1>Modifier mon rapport</h1>
        <p>que voulez vous modifier ? </p>
    </div>
    <div className="btnchoosethechange">

       <div className="btngroupe">
       <button className="btn1ChooseTheChange" onClick={handleChangeMyTeam}>Modifier ma Team</button>
       <button className="btn2ChooseTheChange" onClick={handleChangeMyTacheSelect}>Ajouter ou Arreter une tache </button>
       <button className="btn3ChooseTheChange" onClick={handleChangeMyTache}>Modifier une team sur une tache </button>
       </div>
       </div>
       </div>
  )
}

export default ChooseTheChange
