import './choixActionsRapport.scss'

const ChoixActionsRapport = ({ onActionSelected, affaire, rapport }) => {
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: '2-digit' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  return (
    <div className="choix-actions-rapport">
        <div className="affaire-ref-actions-rapport">
            <h2>Affaire sélectionnée : {affaire.nomAffaire}</h2>
            <h2>Rapport du : {formatDate(rapport.dateVisite)}</h2>
        </div>
        <div className="btn-group-actions-rapport">
      <button onClick={() => onActionSelected('modifierReunion')}>
        Ajouter ou Modifier la Réunion
      </button>
      <button onClick={() => onActionSelected('rapportChantierGeneral')}>
        Rapport sur Chantier en Général
      </button>
      <button onClick={() => onActionSelected('rapportTachesChantier')}>
        Rapport sur les Tâches Chantier
      </button>
      <button onClick={() => onActionSelected('amelioration')}>
        Amélioration
      </button>
    </div>
    </div>
  )
}

export default ChoixActionsRapport
