import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './changeMyTache.scss'

const ChangeMyTache = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { tacheDay: tacheDayInitial, rapportId } = location.state
  const [modifierMode, setModifierMode] = useState(false)
  const [dataRapport, setDataRapport] = useState(null)
  const [newAssignesTacheDay, setNewAssignesTacheDay] = useState([])
  const [usersDisponibles, setUsersDisponibles] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/rapports/rapports/${rapportId}`)
        setDataRapport(response.data)
        if (response.data && response.data.teamMembers) {
          // Pour newAssignesTacheDay, récupérer les infos complètes des utilisateurs assignés
          const assignesInfos = response.data.tachesDay.find(
            (t) => t._id === tacheDayInitial._id
          ).assignesTacheDay
          setNewAssignesTacheDay(assignesInfos)

          // Pour usersDisponibles, exclure les utilisateurs déjà assignés
          const disponibles = response.data.teamMembers.filter(
            (user) => !assignesInfos.some((assigne) => assigne._id === user._id)
          )
          setUsersDisponibles(disponibles)
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données', error)
      }
    }
    fetchData()
  }, [rapportId, tacheDayInitial.assignesTacheDay])

  const handleSupprimerUser = (userId) => {
    const userToRemove = newAssignesTacheDay.find(
      (user) => user._id === userId
    )
    setUsersDisponibles([...usersDisponibles, userToRemove])
    setNewAssignesTacheDay(
      newAssignesTacheDay.filter((user) => user._id !== userId)
    )
  }

  const handleAjouterUser = (userId) => {
    const userToAdd = usersDisponibles.find((user) => user._id === userId)
    setNewAssignesTacheDay([...newAssignesTacheDay, userToAdd])
    setUsersDisponibles(usersDisponibles.filter((user) => user._id !== userId))
  }

  const handleValiderModifications = async () => {
    try {
      const assignesTacheDayIds = newAssignesTacheDay.map(user => user._id)
      console.log('IDs des utilisateurs assignés:', assignesTacheDayIds)

      await axios.put(`/rapports/${rapportId}`, {
        tachesDay: dataRapport.tachesDay.map(tache =>
          tache._id === tacheDayInitial._id
            ? { ...tache, assignesTacheDay: assignesTacheDayIds }
            : tache
        )
      })
      navigate('/changemyday', { state: { rapport: dataRapport } })
    } catch (error) {
      console.error('Erreur lors de la mise à jour', error)
    }
  }

  console.log(newAssignesTacheDay)
  console.log(rapportId)
  return (
    <div className="change-my-tache">
      <button onClick={() => navigate(-1)}>Retour</button>
      <div className="titleChangeMyTache">
        <h1>Changer ma tâche</h1>
      </div>
      <div className="containerChangeMyTache">
  <div className="list1ChangeMyTache">
      <span>Tâche en cours:</span>
      <h2>{tacheDayInitial.nomTache}</h2>
      <div className="containlist1ChangeMyTache">
      <ul>
        {newAssignesTacheDay.map((user) => (
          <li key={user._id}>
            {user.nom}
            {modifierMode && (
            <button onClick={() => handleSupprimerUser(user._id)}>
              Supprimer
            </button>
            )}
          </li>
        ))}
      </ul>
      </div>
      </div>
      {!modifierMode && (
        <button className="btn1ChangeMyTache" onClick={() => setModifierMode(true)}>Modifier la Tâche</button>
      )}
      {modifierMode && (
        <div className="list2ChangeMyTache">
          <p>liste des personnes a affecter:</p>

          <div className="containlist2ChangeMyTache">
          <ul>
            {usersDisponibles.map((user) => (
              <li key={user._id}>
                {user.nom}
                <button onClick={() => handleAjouterUser(user._id)}>
                  Ajouter
                </button>
              </li>
            ))}
          </ul>
          </div>
          <button className="btn2ChangeMyTache" onClick={handleValiderModifications}>
            Valider les Modifications
          </button>
          </div>
      )}
      </div>
    </div>
  )
}

export default ChangeMyTache
