import { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DomainIcon from '@mui/icons-material/Domain'
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms'
import ConstructionIcon from '@mui/icons-material/Construction'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import { AuthContext } from '../../context/AuthContext'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import Button from '@mui/material/Button'
import './hamburgerMenu.scss'

const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const { logout } = useContext(AuthContext)
  const { utilisateur } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const isRouteActive = (route) => {
    return location.pathname === route
  }

  // Pour Delogue + Confirmation
  const handleLogout = () => {
    logout()
    // Efface l'utilisateur du local storage
    localStorage.removeItem('utilisateur')
    navigate('/')
  }

  return (
    <>
      <div className="hamburger-menu">
      <MenuIcon className="menu-icon" onClick={toggleMenu} />
        <div className={`menu-content ${isMenuOpen ? 'open' : ''}`}>
        <div className="user-space">
            Mon espace {utilisateur?.prenom}
          </div>
          <Link to="/" className={isRouteActive('/') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <HomeIcon />
            Home
          </Link>
         {/*<Link to="/clockings" className={isRouteActive('/clockings') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <AccessAlarmsIcon className="iconhamburger"/>
            Pointages
            </Link>*/}
          <Link to="/affaires" className={isRouteActive('/affaires') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <DomainIcon className="iconhamburger"/>
            Affaires
          </Link>
          {/* <Link to="/calendrier" className={isRouteActive('/calendrier') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <CalendarMonthIcon className="iconhamburger"/>
            Calendrier
          </Link>*/}
          <Link to="/calendrierassignation" className={isRouteActive('/calendrierassignation') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <CalendarMonthIcon className="iconhamburger"/>
            Calendrier
          </Link>
          <Link to="/messagerie" className={isRouteActive('/messagerie') ? 'menu-link active' : 'menu-link'} onClick={toggleMenu}>
            <ForumOutlinedIcon className="iconhamburger"/>
            Messagerie
          </Link>
          <div className="menu-link" onClick={() => setOpenDialog(true)}>
            <LogoutIcon className="iconhamburger"/>
            Déconnexion
          </div>
        </div>
        <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{'Confirmer la Déconnexion'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir vous déconnecter ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Non
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      {isMenuOpen && <div className="menu-backdrop" onClick={toggleMenu} />}
    </>
  )
}

export default HamburgerMenu
