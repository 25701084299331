import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import useFetch from '../../../../Hooks/useFetch'
import { AuthContext } from '../../../../context/AuthContext'
import './defineMyTeam.scss'

const DefineMyTeam = () => {
  const { utilisateur } = useContext(AuthContext)
  const location = useLocation()
  const rapportId = location.state?.rapportId
  const [recherche, setRecherche] = useState('')
  const [utilisateurs, setUtilisateurs] = useState([])
  const [selectionnes, setSelectionnes] = useState([])
  const navigate = useNavigate()
  const { data, loading, error } = useFetch('/utilisateurs')

  useEffect(() => {
    if (data) {
      // Filtrer pour exclure l'utilisateur en ligne et inclure seulement ceux avec ouvrierTerrain et chefAssignes à true
      const filtres = data.filter(u => u._id !== utilisateur._id && (u.ouvrierTerrain || u.chefAssignes))
      setSelectionnes(data.filter(u => u._id === utilisateur._id))

      // Éliminer les doublons
      const utilisateursUniques = Array.from(new Set(filtres.map(u => u._id)))
        .map(id => {
          return filtres.find(u => u._id === id)
        })

      setUtilisateurs(utilisateursUniques)
    }
  }, [data, utilisateur])

  const handleRechercheChange = (e) => {
    setRecherche(e.target.value.toLowerCase())
  }

  const handleSelectUser = (utilisateur) => {
    setSelectionnes(prev => [...prev, utilisateur])
    // Retirer l'utilisateur sélectionné de la liste des utilisateurs
    setUtilisateurs(prev => prev.filter(u => u._id !== utilisateur._id))
  }

  const handleDeleteUser = (id) => {
    setSelectionnes(prev => prev.filter(utilisateur => utilisateur._id !== id))
    // Rajouter l'utilisateur supprimé à la liste des utilisateurs
    const utilisateurSupprime = data.find(u => u._id === id)
    setUtilisateurs(prev => [...prev, utilisateurSupprime])
  }

  const utilisateursFiltres = utilisateurs.filter(utilisateur =>
    utilisateur.nom.toLowerCase().includes(recherche)
  )

  // Bouton "Suivant" - Envoie la liste des utilisateurs sélectionnés
  const handleNextPage = async () => {
    if (!rapportId) {
      console.error('ID de rapport manquant')
      return
    }

    try {
      // Préparer les données pour la requête PUT
      const teamData = {
        chefAssignes: utilisateur._id, // ID du chef de l'équipe
        teamMembers: selectionnes.map(u => u._id) // IDs des membres sélectionnés
      }

      // Envoyer la requête PUT
      const response = await axios.put(`/rapports/${rapportId}`, teamData)
      console.log(response.data) // Log pour vérification
      navigate('/definemyjob', { state: { rapportId } })
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données de l\'équipe', error)
    }
  }

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  return (

        <div className="DefineMyTeam">
            <div className="titleDefineMyTeam">
                <h2>{"avec qui etes vous aujourd'hui"}</h2>
                <p>Choisisser dans la liste ajouter ou suprimmer</p>
            </div>
            <div className="rechDefineMyTeam">
            <input type="text" placeholder="Rechercher..." onChange={handleRechercheChange} />
            </div>
            <div className='utilDefineMyTeam'>
                {utilisateursFiltres.map(utilisateur => (
                    <div key={utilisateur._id} onClick={() => handleSelectUser(utilisateur)} className='listbasDefineMyTeam'>
                        {utilisateur.nom}
                    </div>
                ))}
            </div>
            <div className='utilisateursDefineMyTeam'>
                {selectionnes.map(utilisateur => (
                    <div key={utilisateur._id} className='listDefineMyTeam'>
                        {utilisateur.nom}
                        <button onClick={() => handleDeleteUser(utilisateur._id)}>Supprimer</button>
                    </div>
                ))}
            </div>

            <button onClick={handleNextPage}>Suivant</button>
            </div>
  )
}

export default DefineMyTeam
