import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import useFetch from '../../../../Hooks/useFetch'
import axios from 'axios'
import './rapportTachesChantier.scss'

const RapportTachesChantier = ({ affaire, rapport, onBack, onUpdateRapport }) => {
  const { data: taches, isLoading, error } = useFetch(`/affaires/taches/${affaire._id}`)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedTache, setSelectedTache] = useState(null)
  const [observations, setObservations] = useState({
    commentaires: '',
    pourcentageRealisation: 0,
    dansLesTemps: false,
    securiteRespectee: false,
    qualiteTravailRespectee: false
  })

  // Ouvrir le dialogue pour ajouter des observations
  // Réinitialiser les observations lorsque le dialogue est ouvert
  const handleOpenDialog = (tache) => {
    setSelectedTache(tache)

    // Trouver les observations existantes pour cette tâche
    const tacheObservations = rapport.observationsTaches.find(obs => obs.tacheId === tache._id)

    // Définir les observations existantes ou utiliser des valeurs par défaut
    setObservations({
      commentaires: tacheObservations?.commentaires || '',
      pourcentageRealisation: tacheObservations?.pourcentageRealisation || 0,
      dansLesTemps: tacheObservations?.dansLesTemps || false,
      securiteRespectee: tacheObservations?.securiteRespectee || false,
      qualiteTravailRespectee: tacheObservations?.qualiteTravailRespectee || false
    })

    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  // Gestion des changements dans les inputs

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setObservations(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  // Soumission des observations

  const handleSubmit = async () => {
    try {
      const payload = {
        tacheId: selectedTache._id,
        ...observations
      }

      const response = await axios.put(`/rapportsvisite/observationstache/${rapport._id}`, payload)
      console.log("Réponse de l'API:", response.data)

      // Mise à jour du rapport dans le composant parent
      onUpdateRapport(response.data)

      // Fermer le dialogue après soumission
      handleCloseDialog()
    } catch (error) {
      console.error('Erreur lors de la soumission des observations:', error)
    }
  }

  // render tâches

  const renderTaches = () => {
    if (isLoading) return <div>Chargement...</div>
    if (error) return <div>Erreur lors du chargement des tâches</div>

    return taches
      .filter(tache => tache.typeTache === 'Chantier')
      .map(tache => (
        <div
        className="tache-chantier-item-container-rtc"
        key={tache._id} onClick={() => handleOpenDialog(tache)}>
          {tache.nomTache}
        </div>
      ))
  }

  console.log('taches', taches)
  return (
    <div className="rapport-taches-chantier">
      <div className="title-rapport-taches-chantier">
      <h2>Tâches de Chantier:</h2>
      </div>
      {renderTaches()}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Observations sur la Tâche</DialogTitle>
        <DialogContent>
          <TextField
            label="Commentaires"
            multiline
            rows={4}
            name="commentaires"
            value={observations.commentaires}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Pourcentage de réalisation réel"
            type="number"
            name="pourcentageRealisation"
            value={observations.pourcentageRealisation}
            onChange={handleInputChange}
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={observations.dansLesTemps} onChange={handleInputChange} name="dansLesTemps" />}
              label="Dans les temps"
            />
            <FormControlLabel
              control={<Checkbox checked={observations.securiteRespectee} onChange={handleInputChange} name="securiteRespectee" />}
              label="Sécurité respectée"
            />
            <FormControlLabel
              control={<Checkbox checked={observations.qualiteTravailRespectee} onChange={handleInputChange} name="qualiteTravailRespectee" />}
              label="Qualité du travail respectée"
            />
          </FormGroup>
          <Button onClick={handleSubmit} color="primary">
            Soumettre
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RapportTachesChantier
