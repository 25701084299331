import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import useFetch from '../../../../Hooks/useFetch'
import './defineMyTache.scss'
import CheckAndConfirm from '../CheckAndConfirm/CheckAndConfirm'

const DefineMyTache = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const rapportId = location.state?.rapportId
  const [recherche, setRecherche] = useState('')
  const [taches, setTaches] = useState([])
  const { data: rapport, loading: loadingRapport, error: errorRapport } = useFetch(`/rapports/${rapportId}`)
  const [affaireId, setAffaireId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (rapport) {
      setAffaireId(rapport.affaireId)
    }
  }, [rapport])

  useEffect(() => {
    if (affaireId) {
      const fetchTaches = async () => {
        try {
          const response = await axios.get(`/taches/byAffaire/type/${affaireId}?typeTache=Chantier`)
          setTaches(response.data)
        } catch (error) {
          console.error('Erreur lors de la récupération des tâches', error)
        }
      }
      fetchTaches()
    }
  }, [affaireId])

  const handleRechercheChange = (e) => {
    setRecherche(e.target.value.toLowerCase())
  }

  const handleTacheClick = (tacheId) => {
    navigate('/assignmytache', { state: { tacheId, rapportId } })
  }

  const tachesFiltrees = taches.filter(tache =>
    tache.nomTache.toLowerCase().includes(recherche)
  )

  // Chargement et erreur
  if (loadingRapport) return <div>Chargement des informations du rapport...</div>
  if (errorRapport) return <div>Erreur: {errorRapport.message}</div>

  // Boite de dialogue Check and Confirm

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleValidTache = () => {
    setOpenDialog(true) // Ouvrir le dialogue
  }

  return (
        <div className="DefineMyTache">
          <div className="titleDefineMyTache">
            <h1>Choisissez votre tâche</h1>
            <p>Choisissez la ou les taches ou vous allez intervenir</p>
          </div>
            <input className="rechDefineMyTache" type="text" placeholder="Rechercher une tâche..." onChange={handleRechercheChange} />
            <div className="listDefineMyTache">
                {tachesFiltrees.map((tache) => (
                    <div key={tache._id} onClick={() => handleTacheClick(tache._id)} className="mapDefineMyTache">
                        {tache.nomTache}
                    </div>
                ))}
            </div>
            <button className="btnDefineMyTache" onClick={handleValidTache}>Verifier et Commencer</button>
            <CheckAndConfirm
                open={openDialog}
                onClose={handleCloseDialog}
                rapportId={rapportId}
              />
        </div>
  )
}

export default DefineMyTache
