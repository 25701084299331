import { useState } from 'react'
import useFetch from '../../../../Hooks/useFetch'
import './selectAffaire.scss'

const SelectAffaire = ({ onSelectAffaire }) => {
  const { data: affaires, loading, error } = useFetch('/affaires')
  const [selectedAffaireId, setSelectedAffaireId] = useState('')

  const handleChange = (e) => {
    setSelectedAffaireId(e.target.value)
  }

  const handleSubmit = () => {
    const selectedAffaire = affaires.find(
      (affaire) => affaire._id === selectedAffaireId
    )
    onSelectAffaire(selectedAffaire)
  }

  if (loading) return <p>Chargement des affaires...</p>
  if (error) return <p>Erreur de chargement : {error}</p>

  return (
    <div className="SelectAffaire">
        <div className="title-select-affaire">
      <h3>Sélectionner une Affaire</h3>
      </div>
      <div className="select-affaire-contain">
      <select className="select-select-affaire-contain" value={selectedAffaireId} onChange={handleChange}>
        <option value="" disabled>
          Choisissez une affaire
        </option>
        {affaires.map((affaire) => (
          <option key={affaire._id} value={affaire._id}>
            {affaire.nomAffaire}
          </option>
        ))}
      </select>
      <button className="btn-select-affaire-contain" onClick={handleSubmit}>Sélectionner</button>
      </div>
    </div>
  )
}

export default SelectAffaire
