import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Button,
  Switch,
  FormControlLabel
} from '@mui/material'
import BtnEndMyDay from '../BtnEndMyDay/BtnEndMyDay'
import './progressionTachesDay.scss'

const ProgressionTachesDay = () => {
  const location = useLocation()
  const { rapport } = location.state || {}
  const [tachesEtat, setTachesEtat] = useState([])
  // const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffect(() => {
    if (!rapport || !rapport.tachesDay) {
      setTachesEtat({})
    } else {
      const fetchTachesDetails = async () => {
        try {
          const tachesDetailsPromises = rapport.tachesDay.map((tache) =>
            axios.get(`/taches/${tache.tacheIdDay}`)
          )
          const tachesResponses = await Promise.all(tachesDetailsPromises)
          const tachesDetails = tachesResponses.map(
            (response) => response.data
          )

          const etatsInitiaux = tachesDetails.reduce(
            (acc, tache) => ({
              ...acc,
              [tache._id]: {
                nomTache: tache.nomTache,
                pourcentageRealisation: tache.pourcentageRealisation,
                fait: tache.pourcentageRealisation === 100
              }
            }),
            {}
          )
          setTachesEtat(etatsInitiaux)
        } catch (error) {
          console.error(
            'Erreur lors du chargement des détails des tâches',
            error
          )
        }
      }

      fetchTachesDetails()
    }
  }, [rapport])

  const handleTacheUpdate = async (tacheId, pourcentage, isFait) => {
    let update
    if (isFait) {
      update = { pourcentageRealisation: 100, fait: true }
    } else {
      update = { pourcentageRealisation: pourcentage, fait: false }
    }

    try {
      const response = await axios.put(`/taches/${tacheId}`, update)
      setTachesEtat((prev) => ({
        ...prev,
        [tacheId]: { ...prev[tacheId], ...update }
      }))
      console.log('Mise à jour réussie', response.data)
    } catch (error) {
      console.error('Erreur lors de la mise à jour', error)
    }
    // setOpenConfirmDialog(false)
  }
  console.log('rapport', rapport)
  console.log('tacheData', tachesEtat)

  return (
    <div className="ProgressionTachesDay">
      {Object.entries(tachesEtat).map(([tacheId, tacheData]) => (
        <div key={tacheId}
        className="progression-taches-day-item"
        >

          <div className="title-ptd-h3">
            <h3>Avance de la tâche : {tacheData.nomTache}</h3>
          </div>
          {[
            { label: '0%', value: 0 },
            { label: '0 à 25%', value: 25 },
            { label: '25 à 50%', value: 50 },
            { label: '50 à 75%', value: 75 },
            { label: '75 à 99%', value: 99 }
          ].map((item) => (
            <Button
              className="btngroup-ptd"
              key={item.value}
              variant={
                tacheData.pourcentageRealisation === item.value
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                handleTacheUpdate(tacheId, item.value, item.value === 100)
              }
            >
              {item.label}
            </Button>
          ))}
          <Button
            className="btngroup-ptd"
            disabled={!tacheData.fait}
            variant={tacheData.pourcentageRealisation === 100 ? 'contained' : 'outlined'}
            onClick={() => handleTacheUpdate(tacheId, 100, true)}
          >
            100%
          </Button>
          <FormControlLabel
            className="switch-ptd"
            control={
              <Switch

                checked={tacheData.fait}
                onChange={(e) =>
                  handleTacheUpdate(
                    tacheId,
                    e.target.checked ? 100 : 99,
                    e.target.checked
                  )
                }
              />
            }
            label="Tache terminée"
          />
        </div>
      ))}
      <span>
        <BtnEndMyDay rapport={rapport} />
      </span>
    </div>
  )
}

export default ProgressionTachesDay
