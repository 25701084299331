import './reviewAffaire.scss'

const ReviewAffaire = ({ affaire, taches, onReviewComplete }) => {
  return (
    <div>
      <h3>{"Résumé de l'Affaire"}</h3>
      <p>{"Nom de l'affaire:"} {affaire.nomAffaire}</p>
      <h4>Tâches</h4>
      {taches.map((tache, index) => (
        <div key={index}>
          <p>Nom de la tâche: {tache.nomTache}</p>
          <p>Date de début: {tache.dateDepartReelle}</p>
          <p>Date de fin: {tache.dateEcheanceReelle}</p>
        </div>
      ))}
      <button onClick={onReviewComplete}>{"Finaliser l'Affaire"}</button>
    </div>
  )
}

export default ReviewAffaire
