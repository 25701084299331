import { useEffect, useState } from 'react'
import axios from 'axios'

const ChoixRapportDeVisite = ({ affaire, onRapportChange }) => {
  const [rapports, setRapports] = useState([])

  useEffect(() => {
    axios.get(`/rapportsvisite/rapportsaffaire/${affaire._id}`).then((response) => {
      setRapports(response.data)
    })
  }, [affaire])

  const handleCreateNewRapport = () => {
    axios.post('/rapportsvisite', { affaireId: affaire._id }).then((response) => {
      onRapportChange(response.data) // Gère la création d'un nouveau rapport
    })
  }

  return (
    <div>
      {rapports.length > 0
        ? (
            rapports.map((rapport) => (
          <div key={rapport._id} onClick={() => onRapportChange(rapport)}>
            Rapport ID: {rapport._id}
          </div>
            ))
          )
        : (
        <p>Pas de rapports existants pour cette affaire.</p>
          )}
      <button onClick={handleCreateNewRapport}>Créer un Nouveau Rapport</button>
    </div>
  )
}

export default ChoixRapportDeVisite
