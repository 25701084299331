import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeuresTachePost from '../heuresTachePost/HeuresTachePost';
import HeuresTachePut from '../heuresTachePut/HeuresTachePut';
import AddTacheIdRapport from '../addTacheIdRapport/AddTacheIdRapport';

const AffichageHeuresTacheId = ({ tache, date, utilisateur, onClose }) => {
    const [rapports, setRapports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchRapports = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/utilisateurs/rapports/${utilisateur._id}?startDate=${date}`);
                console.log("Rapports récupérés", response.data);
                setRapports(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Erreur lors de la récupération des rapports", err);
                setError(err);
                setLoading(false);
            }
        };

        fetchRapports();
    }, [date, utilisateur._id]);

    if (loading) return <div>Chargement...</div>;
    if (error) return <div>Erreur lors du chargement des données</div>;

    if (rapports && rapports.length > 0) {
        console.log("Recherche d'un rapport correspondant à l'affaire de la tâche", { affaireId: tache.affaireId });
        const rapportCorrespondant = rapports.find(rapport => rapport.affaireId._id === tache.affaireId);
        
        if (rapportCorrespondant) {
            console.log("Rapport correspondant trouvé", rapportCorrespondant);
            const tacheDansRapport = rapportCorrespondant.tachesDay.some(tacheIdDay => tacheIdDay.tacheIdDay._id === tache._id);
            
            if (tacheDansRapport) {
                console.log("La tâche est déjà dans le rapport, redirection vers HeuresTachePut");
                return <HeuresTachePut rapports={rapportCorrespondant} tache={tache} utilisateur={utilisateur} date={date} onClose={onClose} />;
            } else {
                console.log("La tâche n'est pas dans le rapport, redirection vers AddTacheIdRapport");
                return <AddTacheIdRapport tache={tache} rapport={rapportCorrespondant} utilisateur={utilisateur} onClose={onClose} />;
            }
        } else {
            console.log("Aucun rapport correspondant trouvé pour l'affaire de la tâche");
        }
    } else {
        console.log("Aucun rapport existant, redirection vers HeuresTachePost");
    }

    return <HeuresTachePost tache={tache} date={date} utilisateur={utilisateur} onClose={onClose} />;
};

export default AffichageHeuresTacheId;