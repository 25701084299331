import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Checkbox, FormControlLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, Snackbar } from '@mui/material'
import './questionReponseForm.scss'

const questionsStandards = [
  "Avez-vous rencontré des problèmes de matériel aujourd'hui ?",
  "Les conditions météorologiques ont-elles affecté le travail aujourd'hui ?",
  'Y a-t-il eu des retards dans la réalisation des tâches prévues ?',
  'La communication avec le client ou les parties prenantes a-t-elle été efficace ?',
  'Les normes de sécurité ont-elles été respectées tout au long de la journée ?',
  "Des ressources supplémentaires ont-elles été nécessaires aujourd'hui ?"
]

// Options de réponses détaillées pour certaines questions
const optionsDetaillees = {
  0: ['Matériel défectueux ou endommagé', 'Manque de matériel nécessaire', 'Problèmes de livraison de matériel', 'Autres'],
  1: ['Pluie ou inondation', 'Tempête ou vents forts', 'Températures extrêmes (chaud/froid)', 'Autres'],
  2: ["Manque de main-d'œuvre", 'Erreurs dans la planification', 'Problèmes avec le client', 'Modifications imprévues des plans', 'Autres'],
  3: ['Difficultés à joindre les contacts clés', 'Mésentente ou désaccord sur les objectifs', 'Retards dans la réception des approbations', 'Autres'],
  4: ['Incidents de sécurité mineurs', 'Non-respect des procédures de sécurité', 'Equipement de sécurité manquant', 'Autres'],
  5: ["Besoin de main-d'œuvre supplémentaire", 'Demande de matériel supplémentaire', "Demande d'équipement supplémentaire", 'Besoin de plus de temps pour ces tâches', 'Autres']
}

const QuestionReponseForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { rapport } = location.state || {}
  const [questions, setQuestions] = useState([])
  const [reponses, setReponses] = useState({})
  const [commentaireGeneral, setCommentaireGeneral] = useState('')
  // const [reponsesSoumises, setReponsesSoumises] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openDialogAlerte, setOpenDialogAlerte] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null)

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`/rapports/${rapport._id}`)
        const questionsRapport = response.data.questionsReponses || []
        const questionsAUtiliser =
          questionsRapport.length > 0
            ? questionsRapport.map((q) => q.question)
            : questionsStandards
        setQuestions(questionsAUtiliser)
        initialiserReponses(
          questionsRapport.length > 0 ? questionsRapport : questionsStandards
        )
      } catch (error) {
        console.error('Erreur lors du chargement des questions', error)
      }
    }
    fetchQuestions()
  }, [rapport])

  // initialiser les réponses

  const initialiserReponses = (questions) => {
    const initialReponses = {}
    questions.forEach((question, index) => {
      initialReponses[index] = { reponse: null, commentaire: '', optionsDetaillees: '' } // Initialiser avec un objet
    })
    setReponses(initialReponses)
  }

  // setter les réponses complet
  const handleResponseChange = (index, reponse) => {
    let afficherOptionsDetaillees = false
    if ((index === 0 && reponse) || (index === 1 && reponse) || (index === 2 && reponse) || (index === 3 && !reponse) || (index === 4 && !reponse) || (index === 5 && reponse)) {
      afficherOptionsDetaillees = true
    }

    setReponses(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        reponse,
        afficherOptions: afficherOptionsDetaillees
      }
    }))
  }

  // setter les options détaillées
  const handleOptionChange = (index, event) => {
    const nouvelleOption = event.target.value
    setReponses(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        optionsDetaillees: nouvelleOption
      }
    }))
    // Si "Autres" est sélectionné, ouvrir le champ de commentaire
    if (nouvelleOption === 'Autres') {
      handleOpenDialog(index)
    }
  }

  // setter les commentaires
  const handleCommentChange = (comment) => {
    setReponses(prev => ({
      ...prev,
      [selectedQuestionIndex]: {
        ...prev[selectedQuestionIndex],
        commentaire: comment
      }
    }))
  }

  // Envoyer
  const soumettreReponses = async () => {
    // Vérifier si toutes les réponses ont été fournies
    const toutesReponsesPresentes = Object.values(reponses).every(
      (item) => item.reponse !== null
    )
    if (!toutesReponsesPresentes) {
      setOpenDialogAlerte(true) // Ouvrir la Dialog si toutes les réponses ne sont pas présentes
      return
    }
    // Construire l'objet des questions et réponses à envoyer
    const questionsReponsesAEnvoyer = questions.map((question, index) => ({
      question,
      reponse: reponses[index].reponse,
      commentaire: reponses[index].commentaire,
      optionsDetaillees: reponses[index].optionsDetaillees
    }))

    try {
      // Envoyer les réponses au serveur
      await axios.put(`/rapports/${rapport._id}`, {
        questionsReponses: questionsReponsesAEnvoyer,
        commentaireGeneralDay: commentaireGeneral
      })

      // Envoyer un messagerie au utilisateur qui suive l'affaire si questionsReponses a une reponse non voulu
      // Vérifier si `optionsDetaillees` contient des données ou si `commentaireGeneralDay` n'est pas vide
      const aEnvoyerNotifications = questionsReponsesAEnvoyer.some(qr => qr.optionsDetaillees && qr.optionsDetaillees.length > 0) || commentaireGeneral.trim() !== ''

      // info de l'affaire
      if (aEnvoyerNotifications) {
        // detail de l'affaire
        const affaire = await axios.get(`/affaires/${rapport.affaireId}`)
        const affaireData = affaire.data

        // detail des tache day
        const tachesDetails = await Promise.all(
          rapport.tachesDay.map(async (tache) => {
            const response = await axios.get(`/taches/${tache.tacheIdDay}`)
            return response.data
          })
        )
        // ecrire le corp du mail
        const dateRapport = new Date(rapport.date)
        // Construire un texte descriptif pour le rapport
        let descriptionRapport = `Rapport numéro ${rapport._id} du ${dateRapport.toLocaleDateString()}\n\n`

        // Ajouter les informations sur les tâches
        tachesDetails.forEach((tacheDetail) => {
          descriptionRapport += `Nom de la Tâche Travaillé ce Jour : ${tacheDetail.nomTache}\n`
          // Ajoutez d'autres détails de la tâche si nécessaire
        })
        questions.forEach((question, index) => {
          const reponseCorrespondante = reponses[index]
          descriptionRapport += `Question ${index + 1}: ${question}\n`
          descriptionRapport += `Réponse: ${reponseCorrespondante?.reponse ? 'Oui' : 'Non'}\n`
          if (reponseCorrespondante?.optionsDetaillees) {
            descriptionRapport += `Options détaillées: ${reponseCorrespondante.optionsDetaillees}\n`
          }
          descriptionRapport += `Commentaire: ${reponseCorrespondante?.commentaire || 'Aucun commentaire'}\n`
          descriptionRapport += '\n' // Ajouter un saut de ligne pour séparer les questions
        })
        descriptionRapport += `Commentaire général du jour : ${commentaireGeneral || 'Aucun commentaire'}\n\n`

        await axios.post('/taches/createMessageRapport', {
          typeTache: 'messagerie',
          userId: rapport.chefAssignes,
          nomTache: `probleme sur le chantier ${affaireData.nomAffaire}`,
          rapportId: rapport._id,
          affaireId: rapport.affaireId,
          priorite: 'Moyenne',
          description: descriptionRapport,
          usersId: affaireData.usersAssignes
        })
      }

      console.log(reponses)
      // setReponsesSoumises(true)
      setOpenSnackbar(true) // Ouvrir la Snackbar après la soumission réussie
      navigate('/progressiontachesday', { state: { rapport } })
    } catch (error) {
      console.error('Erreur lors de la soumission des réponses', error)
    }
  }

  // dialogue MUI
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleCloseDialogAlerte = () => {
    setOpenDialogAlerte(false)
  }

  // boite commentaire
  const handleOpenDialog = (index) => {
    setSelectedQuestionIndex(index)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  return (
    <div className="container-question-reponse">
{questions.map((question, index) => (
    <div key={index} className="question">
        <p>{question}</p>
        <FormControlLabel
            control={
                <Checkbox
                    checked={reponses[index]?.reponse === true}
                    onChange={() => handleResponseChange(index, true)}
                />
            }
            label="Oui"
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={reponses[index]?.reponse === false}
                    onChange={() => handleResponseChange(index, false)}
                />
            }
            label="Non"
        />
    {reponses[index]?.afficherOptions && (
             <Select
                value={reponses[index].optionsDetaillees || ''}
                displayEmpty
                onChange={(e) => handleOptionChange(index, e)}
                   >
                <MenuItem value="" disabled>
                    Sélectionner une option
                </MenuItem>
                {optionsDetaillees[index].map((option, optionIndex) => (
                    <MenuItem key={optionIndex} value={option}>{option} </MenuItem>
                ))}
              </Select>
    )}
        {reponses[index].optionsDetaillees === 'Autres' && (
            <button onClick={() => handleOpenDialog(index)}>Commentaire</button>
        )}
        <button onClick={() => handleOpenDialog(index)}>+</button>
        {reponses[index]?.commentaire && <p>Commentaire: {reponses[index].commentaire}</p>}
    </div>
))}
      <textarea
        type="text"
        id="commentaireGeneralDay"
        value={commentaireGeneral}
        placeholder="Commenter ma journée"
        onChange={(e) => setCommentaireGeneral(e.target.value)}
      />
        <button onClick={soumettreReponses}>Soumettre</button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Formulaire envoyé"
      />

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Ajouter un commentaire</DialogTitle>
        <DialogContent>
        <TextField
            fullWidth
            multiline
            rows={4}
            value={reponses[selectedQuestionIndex]?.commentaire || ''}
            onChange={(e) => handleCommentChange(e.target.value)}
        />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Annuler</Button>
            <Button onClick={handleCloseDialog} color="primary">Envoyer</Button>
        </DialogActions>

      </Dialog>
      <Dialog
        open={openDialogAlerte}
        onClose={handleCloseDialogAlerte}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {'Veuillez répondre à toutes les questions.'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialogAlerte} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default QuestionReponseForm
