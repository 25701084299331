// ConfirmStopTache.js
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

const ConfirmStopTache = ({ open, onClose, onConfirm, tache }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Arrêter la tâche</DialogTitle>
      <DialogContent>
        Êtes-vous sûr de vouloir arrêter la tâche : {tache?.nomTache} ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Non</Button>
        <Button onClick={() => onConfirm(tache.rapportId, tache._id)} color="primary">Oui</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmStopTache
