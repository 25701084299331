import { useState, useEffect } from 'react'
import axios from 'axios'
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import fr from 'date-fns/locale/fr'
import { differenceInCalendarDays } from 'date-fns'
import './editTache.scss'

registerLocale('fr', fr)
setDefaultLocale('fr')

const EditTache = ({ selectedTache, affaireId, onReturnToListTache }) => {
  const [formData, setFormData] = useState({
    affaireId,
    nomTache: '',
    description: '',
    typeTache: 'Chantier',
    dateDepart: null,
    dateEcheance: null,
    duration: '',
    durationAvecWeekend: ''
  })

  const [editingTacheId, setEditingTacheId] = useState(null)

  useEffect(() => {
    if (selectedTache) {
      setFormData({
        ...selectedTache,
        dateDepart: selectedTache.dateDepart
          ? new Date(selectedTache.dateDepart)
          : null
      })
      setEditingTacheId(selectedTache._id)
    } else {
      resetForm()
    }
  }, [selectedTache])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleDateChange = (date) => {
    console.log('Nouvelle date sélectionnée:', date)
    setFormData({ ...formData, dateDepart: date })
  }

  const resetForm = () => {
    setFormData({
      affaireId,
      nomTache: '',
      description: '',
      typeTache: 'Chantier',
      dateDepart: null,
      dateEcheance: null,
      duration: '',
      durationAvecWeekend: ''
    })
    setEditingTacheId(null)
  }

  // Fonction pour mettre à jour les dates de l'affaire
  const updateAffaireDates = async (affaireId) => {
    try {
      const reponse = await axios.get(`/affaires/datesreelavectaches/${affaireId}`)
      console.log("Mise à jour des dates de l'affaire", reponse.data)
    } catch (error) {
      console.error(`Erreur lors de la mise à jour des dates de l'affaire ${affaireId}`, error)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log('Données du formulaire avant envoi:', formData)
    // Vérification : nomTache doit être renseigné
    if (!formData.nomTache) {
      alert('Nom de la tâche est obligatoire')
      return
    }

    // Vérification : Si duration est renseignée sans dateDepart
    if (formData.duration && !formData.dateDepart) {
      alert('Vous ne pouvez pas rentrer une durée sans date de départ')
      return
    }

    // Si dateDepart est définie mais pas duration, alors duration vaut 1
    if (formData.dateDepart && !formData.duration) {
      formData.duration = 1
    }

    // Calcul de dateEcheance et durationAvecWeekend si dateDepart et duration sont définis
    if (formData.dateDepart && formData.duration) {
      const dateEcheance = new Date(formData.dateDepart)
      let joursAjoutes = 0

      // Commencez à compter à partir du lendemain de la date de départ
      while (joursAjoutes < formData.duration - 1) {
        dateEcheance.setDate(dateEcheance.getDate() + 1)

        // Comptez uniquement les jours de semaine
        if (dateEcheance.getDay() !== 0 && dateEcheance.getDay() !== 6) {
          joursAjoutes++
        }
      }

      formData.dateEcheance = dateEcheance
      formData.durationAvecWeekend =
            differenceInCalendarDays(dateEcheance, new Date(formData.dateDepart)) +
            1
    }

    // Effectuer la requête POST ou PUT
    try {
      const url = editingTacheId ? `/taches/${editingTacheId}` : '/taches'
      const method = editingTacheId ? 'put' : 'post'

      // Effectuer la requête POST ou PUT
      const response = await axios[method](url, formData) // Stocker la réponse
      console.log(formData)
      resetForm()

      // Traitement supplémentaire pour les nouvelles tâches (POST)
      if (!editingTacheId) {
        const newTacheId = response.data._id // Récupérer l'ID de la nouvelle tâche

        // Récupérer l'affaire actuelle
        const affaireResponse = await axios.get(`/affaires/${affaireId}`)
        const currentAffaire = affaireResponse.data

        // Ajouter l'ID de la nouvelle tâche au tableau des tâches de l'affaire
        currentAffaire.taches.push(newTacheId)

        // Changement du statut de l'affaire si c'est la première tâche
        if (currentAffaire.taches.length === 1) {
          currentAffaire.statut = 'en préparation'
        }

        // Mettre à jour l'affaire avec les modifications
        await axios.put(`/affaires/${affaireId}`, currentAffaire)
      }
      // mettre a jour les date chantier seulement si c'est un chantier ou un sous traitant
      if (formData.typeTache === 'Chantier' || formData.typeTache === 'sous traitant') {
        await updateAffaireDates(affaireId)
      }
      onReturnToListTache()
    } catch (err) {
      console.error('Erreur lors de la mise à jour de la tâche', err)
    }
  }

  return (
      <div className="EditTache ">
        <h2>{editingTacheId ? 'Modifier la Tâche' : 'Créer une Nouvelle Tâche'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-edit-tache">
            <label>Nom de la Tâche</label>
            <input
              type="text"
              name="nomTache"
              value={formData.nomTache}
              onChange={handleChange}
              placeholder="Nom de la tâche"
            />
          </div>

          <div className="form-edit-tache">
            <label>Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Description"
            />
          </div>
          <div className="biggroupe-edit-tache">
          <div className="dateandselect-edit-tache">
          <div className="form-group-edit-tache">
            <label>Type de Tâche</label>
            <select
              name="typeTache"
              value={formData.typeTache}
              onChange={handleChange}
            >
              <option value="Chantier">Chantier</option>
              <option value="Bureau">Bureau</option>
              <option value="Administratif">Administratif</option>
              <option value="Technique">Technique</option>
            </select>
          </div>

          <div className="form-group-edit-tache">
            <label>Date de Départ</label>
            <ReactDatePicker
              selected={formData.dateDepart}
              onChange={handleDateChange}
              locale="fr"
              dateFormat="dd/MM/yy"
              placeholderText="Date de départ"
              className="datePicker-edit-tache"
            />
          </div>
          </div>
          <div className="form-group-edit-tache-duration-field">
            <label>Durée (jours)</label>
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              placeholder="Durée"
              min="0"
              max="150"
            />
          </div>
          </div>

          <button type="submit" className="submit-button-edit-tache">
            {editingTacheId ? 'Valider les modifications' : 'Ajouter la tâche'}
          </button>
        </form>
      </div>
  )
}
export default EditTache
