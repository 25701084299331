import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu';
import ChatAffaire from '../../Components/Chat/ChatAffaire/ChatAffaire';
import './messagerieAffaire.scss';

const MessagerieAffaire = () => {
    const { id } = useParams();  // Récupère l'ID de l'affaire depuis l'URL
    const navigate = useNavigate();

    console.log(id)
    // Fonction pour retourner à la page précédente
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className='MessagerieAffaire'>
            <div className="top-MessagerieAffaire">
            <HamburgerMenu />
           
            <button className='btn-retour' onClick={handleBack}>Retour</button>
            </div>
            <div className="contain-MessagerieAffaire">
            <ChatAffaire affaireId={id} />
            </div>
        </div>
    );
}

export default MessagerieAffaire;