import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import './changeMyTeam.scss'

const ChangeMyTeam = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { rapport } = location.state
  const [selectionnes, setSelectionnes] = useState([]) // Utilisateurs actuellement dans l'équipe
  const [utilisateursFiltres, setUtilisateursFiltres] = useState([]) // Utilisateurs disponibles pour l'ajout
  const [openDialog, setOpenDialog] = useState(false)
  const [initialTeamMembers, setInitialTeamMembers] = useState([])

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const rapportResponse = await axios.get(`/rapports/${rapport._id}`)
      const teamMemberIds = rapportResponse.data.teamMembers

      // Récupérer les données complètes pour chaque ID de teamMember
      const teamMemberData = await Promise.all(
        teamMemberIds.map((id) => axios.get(`/utilisateurs/${id}`))
      )

      setSelectionnes(teamMemberData.map((response) => response.data))
      setInitialTeamMembers(teamMemberData.map((response) => response.data))
    }

    fetchTeamMembers()
  }, [rapport._id])

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      const usersResponse = await axios.get('/utilisateurs')
      const filteredUsers = usersResponse.data.filter(
        (user) =>
          (user.ouvrierTerrain || user.chefAssignes) &&
          !selectionnes.some((member) => member._id === user._id)
      )
      setUtilisateursFiltres(filteredUsers)
    }

    fetchAvailableUsers()
  }, [selectionnes])

  const handleAddUser = async (userId) => {
    const userToAdd = utilisateursFiltres.find((user) => user._id === userId)
    if (userToAdd) {
      setSelectionnes((prev) => [...prev, userToAdd])
      setUtilisateursFiltres((prev) =>
        prev.filter((user) => user._id !== userId)
      )
    }
  }

  const handleRemoveUser = async (userId) => {
    const userToRemove = selectionnes.find((user) => user._id === userId)
    if (userToRemove) {
      setSelectionnes((prev) => prev.filter((user) => user._id !== userId))

      // Ajouter l'utilisateur retiré à la liste des utilisateurs disponibles, s'il n'est pas déjà présent
      if (!utilisateursFiltres.some((user) => user._id === userId)) {
        setUtilisateursFiltres((prev) => [...prev, userToRemove])
      }
    }
  }

  const handleValidateChanges = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  // Trouver les utilisateurs ajoutés et retirés
  const addedUsers = selectionnes.filter(
    (member) =>
      !initialTeamMembers.some(
        (initialMember) => initialMember._id === member._id
      )
  )
  const removedUsers = initialTeamMembers.filter(
    (initialMember) =>
      !selectionnes.some((member) => member._id === initialMember._id)
  )

  const confirmChanges = async () => {
    try {
      const currentDateTime = new Date().toISOString()

      // Préparer les mises à jour pour teamMemberHours
      const teamMemberHoursUpdates = rapport.teamMemberHours || []
      addedUsers.forEach((user) => {
        teamMemberHoursUpdates.push({
          utilisateurId: user._id,
          arrivee: currentDateTime
        })
      })
      removedUsers.forEach((user) => {
        const existingRecordIndex = teamMemberHoursUpdates.findIndex(
          (record) => record.utilisateurId === user._id
        )
        if (existingRecordIndex !== -1) {
          teamMemberHoursUpdates[existingRecordIndex].depart = currentDateTime
        }
      })
      // Conservation des membres actuels et ajout des nouveaux membres
      const updatedTeamMembers = initialTeamMembers.map((member) => member._id)
      addedUsers.forEach((user) => {
        if (!updatedTeamMembers.includes(user._id)) {
          updatedTeamMembers.push(user._id)
        }
      })

      // Mettre à jour le rapport
      await axios.put(`/rapports/${rapport._id}`, {
        teamMembers: updatedTeamMembers,
        teamMemberHours: teamMemberHoursUpdates
      })

      navigate('/choosethechange', { state: { rapport } })
      setOpenDialog(false) // Fermer le dialogue
    } catch (error) {
      console.error('Erreur lors de la confirmation des changements', error)
      // Gérer l'erreur (par exemple, afficher un message)
    }
  }

  return (
    <div className="ChangeMyTeam">
      <div className="titleChangeMyTeam">
        <h1>Modifier mon équipe</h1>
      </div>
      <div className="descriptionChangeMyTeam">
      <h2>Équipe Actuelle</h2>
      {selectionnes.map((member) => (
        <div key={member._id} className="listactuelChangeMyTeam">
          {member.nom}
          <button onClick={() => handleRemoveUser(member._id)}>Retirer</button>
        </div>
      ))}
      </div>
      <div className="nouveauChangeMyTeam">
      <h2>nouvelle arrivant en cours</h2>
      {utilisateursFiltres.map((user) => (
        <div key={user._id} className="listnewChangeMyTeam">
          {user.nom}
          <button className="btnnewChangeMyTeam" onClick={() => handleAddUser(user._id)}>Ajouter</button>
        </div>
      ))}
      </div>
      <button className="btnvalidChangeMyTeam" onClick={handleValidateChanges}>Valider les Changements</button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmer les Changements</DialogTitle>
        <DialogContent>
          {addedUsers.length > 0 && (
            <>
              <div>Voulez-vous ajouter :</div>
              {addedUsers.map((member) => (
                <p key={member._id}>{member.nom}</p>
              ))}
            </>
          )}
          {removedUsers.length > 0 && (
            <>
              <div>Confirmez-vous que les personnes ci-dessous partent :</div>
              {removedUsers.map((user) => (
                <p key={user._id}>{user.nom}</p>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={confirmChanges} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ChangeMyTeam
