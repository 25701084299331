import { useState, useCallback } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './createFile.scss'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import axios from 'axios'
import { fileToKey } from '../../../utils/storage'
import { useFirebase } from '../../../context/Firebase'
import Close from '@mui/icons-material/Close'

const CreateFile = ({ affaireId, currentFolderId, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [expirationDate, setExpirationDate] = useState(null)
  const [file, setFile] = useState(undefined)
  const { storage } = useFirebase()

  const handleClose = () => {
    setOpen(prev => !prev)
    setName('')
    setStatus('')
    setExpirationDate(null)
    setFile(undefined)
  }

  const onChangeFile = useCallback(e => {
    const files = e.target.files
    if (!name?.length || (file && file.name === name)) {
      setName(files[0].name)
    }
    setFile(Object.assign(files[0], {
      preview: URL.createObjectURL(files[0])
    }))
  }, [])

  return <>
    <Button variant='contained' onClick={handleClose} color='action'>
      Ajouter un fichier
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault()
          setIsLoading(true)
          const fileUrl = await fileToKey(file, storage)
          axios.post('/files', {
            name: name || file.name,
            url: fileUrl,
            type: file.type,
            folder: false,
            expirationDate,
            status: status || undefined,
            parent: currentFolderId,
            affaire: affaireId
          }).then((res) => {
            callback(res.data)
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          }).finally(() => {
            setIsLoading(false)
          })
        }
      }}
      fullWidth={true}
    >
      <DialogTitle>Ajouter un fichier</DialogTitle>
      <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent className="form">
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          label="Nom du fichier*"
          fullWidth
          variant="outlined"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
        />
        <div className={classNames('dropzone', file ? 'success' : '')}>
          <input id='file' name='file' type='file' onChange={onChangeFile} />
          {file
            ? file.type.includes('image')
              ? <img src={file.preview} />
              : <div className="preview">
                  <InsertDriveFile sx={{ fontSize: 40 }} />
                  <CheckCircleOutline sx={{ fontSize: 40 }} />
                </div>
            : null
          }
        </div>
        <div className="info">
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={status}
              onChange={e => setStatus(e.target.value)}
              input={<OutlinedInput label="Status" />}
            >
              <MenuItem value={''}><em>-</em></MenuItem>
              <MenuItem value='WAITING'>En attente</MenuItem>
              <MenuItem value='IN_PROGRESS'>En cours</MenuItem>
              <MenuItem value='VALIDATED'>Validé</MenuItem>
              <MenuItem value='CONFIRMED'>Confirmé</MenuItem>
              <MenuItem value='ARCHIVED'>Archivé</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <DatePicker
              label="Date d'expiration"
              value={expirationDate}
              onChange={v => setExpirationDate(v)}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={isLoading}>{isLoading ? <CircularProgress /> : 'Ajouter le fichier'}</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default CreateFile
