import { useState } from 'react'

import './createAffaire.scss'

const CreateAffaire = ({ onCreateAffaire }) => {
  const [affaireName, setAffaireName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    onCreateAffaire({ id: 'newAffaireId', name: affaireName })
  }

  return (
    <div>
      <h2>Créer une Nouvelle Affaire</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={affaireName}
          onChange={(e) => setAffaireName(e.target.value)}
          placeholder="Nom de l'affaire"
        />
        <button type="submit">Créer</button>
      </form>
    </div>
  )
}

export default CreateAffaire
