import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import useFetch from '../../Hooks/useFetch';
import HamburgerMenu from '../../Components/hamburgerMenu/HamburgerMenu';
import FolderIcon from '@mui/icons-material/Folder';
import HandymanIcon from '@mui/icons-material/Handyman';
import './affaire.scss';

const Affaire = () => {
  const { data: affaires, loading, error } = useFetch('/affaires');
  const { utilisateur } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const statutsAutorises = ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'];

  const filteredAffaires = affaires?.filter(affaire =>
    statutsAutorises.includes(affaire.statut) 
    //&&
    //(
      // Si l'utilisateur est admin et conducAssignes, affiche toutes les affaires
     // (utilisateur?.conducAssignes || affaire.chefAssignes.includes(utilisateur?._id)) &&
     // affaire.nomAffaire.toLowerCase().includes(searchTerm.toLowerCase())
   // )
  );

  const handleSubMenu = (id) => {
    setOpenSubMenu(openSubMenu === id ? null : id);
  };

  return (
      <div className='affaire-page'>
        <HamburgerMenu />
        <div className="page-header">
          <h1>Affaires</h1>
          <input
              type="text"
              placeholder="Rechercher une affaire..."
              onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {loading && <p>Chargement...</p>}
        {error && <p>Une erreur est survenue.</p>}
        <div className='affaire-list'>
            {filteredAffaires.map(affaire => (
                <div className="affaire-item" key={affaire._id} onClick={() => handleSubMenu(affaire._id)}>
                    <p>{affaire.nomAffaire}</p>
                    {openSubMenu === affaire._id && (
                        <div className='submenu'>
                              <Link to={`/documents/${affaire._id}`} className="menu-link">
                                <FolderIcon className='icon-affaire-page'/>
                                  <p>Documents</p>
                              </Link>
                              <Link to={`/resum/${affaire._id}`} className="menu-link">
                                <HandymanIcon className='icon-affaire-page'/>
                                <p>Résumé</p>
                              </Link>
                            {utilisateur?.conducAssignes && (
                                <>
                                    <Link to={`/option1/${affaire._id}`}>Option 1</Link>
                                    <Link to={`/option2/${affaire._id}`}>Option 2</Link>
                                </>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
      </div>
  );
};

export default Affaire;
