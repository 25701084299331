import './selectOrCreateAffaire.scss'

const SelectOrCreateAffaire = ({ onSelectAffaire, onCreateAffaire }) => {
  return (
    <div className="SelectOrCreateAffaire">
      <div className="title-SelectOrCreateAffaire">
        <h2>Choisir ou Créer une Affaire</h2>
      </div>
      <div className="btn-SelectOrCreateAffaire">
        <button onClick={() => onSelectAffaire({ id: 'existingAffaireId' })}>
          Sélectionner une Affaire
        </button>
        <button onClick={() => onCreateAffaire({ id: 'newAffaireId' })}>
          Créer une Nouvelle Affaire
        </button>
      </div>
    </div>
  )
}

export default SelectOrCreateAffaire
