import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import useFetch from '../../../../Hooks/useFetch'
import { AuthContext } from '../../../../context/AuthContext'

import './defineMyJob.scss'

const DefineMyJob = () => {
  const { utilisateur } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const rapportId = location.state?.rapportId
  const [recherche, setRecherche] = useState('')
  const [selectedAffaire, setSelectedAffaire] = useState(null)
  const [affaires, setAffaires] = useState([])
  const [showAllAffaires, setShowAllAffaires] = useState(false)
  const {
    data: affaireUtilisateurId,
    loading,
    error
  } = useFetch(`affaires/chefAssignes/${utilisateur._id}`)
  const { data: toutesAffaireData } = useFetch('affaires/')

  useEffect(() => {
    if (affaireUtilisateurId) {
        setAffaires(affaireUtilisateurId);
    }
    if (toutesAffaireData && showAllAffaires) {
        // Filtrer pour garder uniquement les affaires 'en cours' et 'terminé'
        const affairesFiltrees = toutesAffaireData.filter((affaire) =>
            affaire.statut === 'en cours' || affaire.statut === 'terminé'
        );
        setAffaires(affairesFiltrees);
    }
}, [affaireUtilisateurId, toutesAffaireData, showAllAffaires]);

  const handleRechercheChange = (e) => {
    setRecherche(e.target.value.toLowerCase())
  }

  const handleSelectAffaire = (affaire) => {
    setSelectedAffaire(affaire)
  }

  const handleDeleteAffaire = () => {
    setSelectedAffaire(null)
  }

  const handleShowAllAffaires = () => {
    setShowAllAffaires(true)
  }

  const affairesFiltrees = affaires.filter((affaire) =>
    affaire.nomAffaire.toLowerCase().includes(recherche)
  )

  // Bouton bas
  const handleSelectTache = async () => {
    if (!rapportId || !selectedAffaire) {
      console.error("ID de rapport ou ID d'affaire manquant")
      return
    }
    try {
      const affaireData = {
        affaireId: selectedAffaire._id // ID de l'affaire sélectionnée
      }
      // Envoyer la requête PUT
      const response = await axios.put(`/rapports/${rapportId}`, affaireData)
      console.log(response.data) // Log pour vérification
      navigate('/definemytache', { state: { rapportId } })
    } catch (error) {
      console.error("Erreur lors de l'envoi des données de l'affaire", error)
    }
  }

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  return (
    <div className="definemyjobcontainer">
      <div className="titledefinemyjobcontainer">
        <h2>Définissez votre chantier</h2>
        <p>{"Vous ne pouvez choisir qu'un chantier"}</p>
      </div>
      <input
        className="definemyjobinput"
        type="text"
        placeholder="Rechercher..."
        onChange={handleRechercheChange}
      />
      {selectedAffaire && (
        <div className="selecteddefinemyjobcontainer">
          {selectedAffaire.nomAffaire}
          <button onClick={handleDeleteAffaire}>Supprimer</button>
        </div>
      )}
      <div className="listdefinemyjobcontainer">
        {affairesFiltrees.map((affaire) => (
          <div key={affaire._id} onClick={() => handleSelectAffaire(affaire)} className="affairedefinemyjobcontainer">
            {affaire.nomAffaire}
          </div>
        ))}
      </div>
      {!showAllAffaires && !selectedAffaire && ( // Condition modifiée ici pour inclure la vérification de selectedAffaire
        <button onClick={handleShowAllAffaires} className="btnaffaire">
          Afficher toutes les affaires
        </button>
      )}
      {selectedAffaire && (
        <button className="btnsuivant" onClick={handleSelectTache}>
          Définir mes tache(s)
        </button>
      )}
    </div>
);
}

export default DefineMyJob
