import { useState } from 'react'
import { TextField, Button } from '@mui/material'
import axios from 'axios'
import './ameliorationRapportVisite.scss'

const AmeliorationRapportVisite = ({ affaire, rapport, onBack, onUpdateRapport }) => {
  const [ameliorations, setAmeliorations] = useState({
    ressources: rapport.ressources || { materiel: [], mainDoeuvre: [] },
    analyseRisques: rapport.analyseRisques || [],
    problemesRencontres: rapport.problemesRencontres || [],
    planificationPrevisions: rapport.planificationPrevisions || '',
    coordination: rapport.coordination || '',
    indicateursPerformance: rapport.indicateursPerformance || {
      incidentsSecurite: 0,
      retards: 0
    },
    feedbackClientsVisiteurs: rapport.feedbackClientsVisiteurs || ''
  })

  const handleListChange = (value, index, listName) => {
    setAmeliorations(prev => ({
      ...prev,
      [listName]: prev[listName].map((item, i) => i === index ? value : item)
    }))
  }

  const handleAddToList = (listName) => {
    setAmeliorations(prev => ({
      ...prev,
      [listName]: [...prev[listName], '']
    }))
  }

  const handleRemoveFromList = (index, listName) => {
    setAmeliorations(prev => ({
      ...prev,
      [listName]: prev[listName].filter((_, i) => i !== index)
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    // Mise à jour pour les indicateurs de performance
    if (name.startsWith('indicateursPerformance.')) {
      const fieldName = name.split('.')[1]
      setAmeliorations(prev => ({
        ...prev,
        indicateursPerformance: {
          ...prev.indicateursPerformance,
          [fieldName]: value
        }
      }))
    } else {
      // Mise à jour pour les autres champs
      setAmeliorations(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await axios.put(`/rapportsvisite/ameliorations/${rapport._id}`, ameliorations)
      onUpdateRapport(response.data)
      onBack()
    } catch (error) {
      console.error('Erreur lors de la mise à jour du rapport:', error)
    }
  }

  return (

    <div className="amelioration-rapport-visite">

      <div className="title-amelioration-rapport-visite">
      <h2>Améliorations:</h2>
      </div>
      <TextField
        label="Planification et Prévisions"
        name="planificationPrevisions"
        value={ameliorations.planificationPrevisions}
        onChange={handleInputChange}
        fullWidth
        multiline
      />
      <TextField
        label="Coordination"
        name="coordination"
        value={ameliorations.coordination}
        onChange={handleInputChange}
        fullWidth
        multiline
      />
      <TextField
        label="Feedback Clients/Visiteurs"
        name="feedbackClientsVisiteurs"
        value={ameliorations.feedbackClientsVisiteurs}
        onChange={handleInputChange}
        fullWidth
        multiline
      />
            <TextField
        label="Incidents de Sécurité"
        type="number"
        name="indicateursPerformance.incidentsSecurite"
        value={ameliorations.indicateursPerformance.incidentsSecurite}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Retards"
        type="number"
        name="indicateursPerformance.retards"
        value={ameliorations.indicateursPerformance.retards}
        onChange={handleInputChange}
        fullWidth
      />
      <div className="contain-ajout-arv">
      <div className="title-arv">
              <h3>Analyse des Risques</h3>
        </div>
        {ameliorations.analyseRisques.map((risque, index) => (
          <div key={index}>
            <TextField
              value={risque}
              onChange={(e) => handleListChange(e.target.value, index, 'analyseRisques')}
              fullWidth
            />
            <Button onClick={() => handleRemoveFromList(index, 'analyseRisques')}>Supprimer</Button>
          </div>
        ))}
        <div className="btn-ajout-arv">
        <Button onClick={() => handleAddToList('analyseRisques')}>+</Button>
        <label>Ajouter Risque</label>
        </div>
      </div>

      <div className="contain-ajout-arv">
      <div className="title-arv">
        <h3>Problèmes Rencontrés</h3>
        </div>
        {ameliorations.problemesRencontres.map((probleme, index) => (
          <div key={index}>
            <TextField
              value={probleme}
              onChange={(e) => handleListChange(e.target.value, index, 'problemesRencontres')}
              fullWidth
            />
            <Button onClick={() => handleRemoveFromList(index, 'problemesRencontres')}>Supprimer</Button>
          </div>
        ))}
        <div className="btn-ajout-arv">
        <Button onClick={() => handleAddToList('problemesRencontres')}>+</Button>
        <label>Ajouter Problème</label>
        </div>
      </div>
      <button onClick={handleSubmit} >Soumettre les Améliorations</button>
    </div>
  )
}

export default AmeliorationRapportVisite
